<template>
  <div class="content" style="background-color: #f7f8fa !important">
    <!-- Navbar -->
    <base-nav class="navbar-horizontal" expand>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        style="cursor: pointer"
        @click="backHome()"
        class="logo left"
        width="99pt"
        height="22pt"
        viewBox="0 0 99 22"
        version="1.1"
      >
        <g id="surface1">
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 28.433594 7.222656 C 29.46875 7.222656 30.269531 7.4375 30.835938 7.859375 C 31.402344 8.289062 31.820312 8.886719 32.023438 9.574219 C 32.257812 10.351562 32.371094 11.15625 32.363281 11.96875 L 32.363281 18.996094 L 30.117188 18.996094 L 30.117188 12.125 C 30.125 11.617188 30.050781 11.113281 29.898438 10.628906 C 29.765625 10.214844 29.507812 9.851562 29.15625 9.597656 C 28.71875 9.328125 28.203125 9.21875 27.695312 9.289062 C 27.15625 9.28125 26.621094 9.40625 26.148438 9.664062 C 25.714844 9.894531 25.347656 10.230469 25.078125 10.640625 C 24.816406 11.042969 24.679688 11.519531 24.683594 12 L 24.683594 18.996094 L 22.4375 18.996094 L 22.4375 7.464844 L 24.683594 7.464844 L 24.683594 9.289062 C 25.082031 8.699219 25.605469 8.207031 26.210938 7.847656 C 26.878906 7.433594 27.652344 7.214844 28.433594 7.222656 Z M 28.433594 7.222656 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 45.03125 17.480469 L 45.007812 17.480469 C 43.773438 18.578125 42.1875 19.195312 40.539062 19.214844 C 39.441406 19.238281 38.355469 18.980469 37.386719 18.457031 C 36.503906 17.972656 35.769531 17.25 35.269531 16.371094 C 34.75 15.449219 34.488281 14.410156 34.507812 13.351562 C 34.472656 12.195312 34.75 11.050781 35.3125 10.042969 C 35.824219 9.160156 36.5625 8.429688 37.453125 7.9375 C 38.339844 7.445312 39.335938 7.191406 40.351562 7.199219 C 41.269531 7.1875 42.179688 7.433594 42.96875 7.910156 C 43.757812 8.386719 44.40625 9.066406 44.851562 9.875 C 45.335938 10.769531 45.578125 11.777344 45.554688 12.796875 L 45.535156 13.765625 L 36.746094 13.765625 C 36.832031 14.722656 37.289062 15.613281 38.019531 16.234375 C 38.792969 16.859375 39.765625 17.183594 40.757812 17.148438 C 41.429688 17.175781 42.101562 17.023438 42.695312 16.699219 C 43.128906 16.441406 43.539062 16.152344 43.917969 15.828125 Z M 40.34375 9.289062 C 39.550781 9.269531 38.773438 9.476562 38.097656 9.890625 C 37.457031 10.292969 37.035156 10.988281 36.832031 11.96875 L 43.242188 11.96875 L 43.242188 11.824219 C 43.210938 11.34375 43.042969 10.882812 42.757812 10.496094 C 42.476562 10.117188 42.113281 9.8125 41.695312 9.601562 C 41.273438 9.390625 40.8125 9.285156 40.347656 9.289062 Z M 40.34375 9.289062 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 55.128906 3.488281 C 55.949219 3.480469 56.765625 3.605469 57.546875 3.859375 C 58.242188 4.089844 58.902344 4.414062 59.507812 4.824219 C 60.023438 5.171875 60.472656 5.601562 60.839844 6.101562 L 59.316406 7.707031 C 58.742188 7.15625 58.097656 6.695312 57.394531 6.332031 C 56.691406 5.972656 55.914062 5.789062 55.128906 5.792969 C 54.144531 5.78125 53.179688 6.03125 52.324219 6.519531 C 51.503906 6.988281 50.824219 7.667969 50.351562 8.484375 C 49.863281 9.34375 49.613281 10.320312 49.632812 11.308594 C 49.617188 12.308594 49.882812 13.292969 50.394531 14.152344 C 50.894531 14.988281 51.59375 15.6875 52.433594 16.171875 C 53.28125 16.667969 54.246094 16.921875 55.226562 16.910156 C 55.929688 16.914062 56.632812 16.769531 57.277344 16.480469 C 57.882812 16.21875 58.417969 15.8125 58.832031 15.296875 C 59.230469 14.8125 59.441406 14.207031 59.4375 13.582031 L 59.4375 13.460938 L 55.085938 13.460938 L 55.085938 11.113281 L 61.84375 11.113281 C 61.871094 11.335938 61.898438 11.554688 61.921875 11.773438 C 61.945312 11.996094 61.953125 12.214844 61.953125 12.433594 C 61.953125 13.238281 61.832031 14.035156 61.59375 14.804688 C 61.371094 15.550781 60.984375 16.242188 60.46875 16.824219 C 59.863281 17.578125 59.089844 18.175781 58.214844 18.570312 C 57.253906 19.007812 56.207031 19.230469 55.15625 19.21875 C 53.054688 19.238281 51.03125 18.414062 49.53125 16.933594 C 48.804688 16.21875 48.230469 15.363281 47.839844 14.417969 C 47.429688 13.433594 47.21875 12.375 47.226562 11.308594 C 47.21875 10.25 47.421875 9.203125 47.828125 8.226562 C 48.21875 7.296875 48.789062 6.453125 49.507812 5.746094 C 50.230469 5.03125 51.085938 4.46875 52.027344 4.089844 C 53.011719 3.683594 54.0625 3.480469 55.128906 3.488281 Z M 55.128906 3.488281 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 74.910156 7.464844 L 74.910156 18.996094 L 72.632812 18.996094 L 72.632812 17.21875 C 72.210938 17.769531 71.683594 18.230469 71.085938 18.578125 C 70.367188 19.015625 69.542969 19.234375 68.707031 19.214844 C 67.777344 19.222656 66.863281 18.957031 66.082031 18.445312 C 65.273438 17.917969 64.625 17.179688 64.199219 16.3125 C 63.71875 15.347656 63.472656 14.277344 63.492188 13.195312 C 63.46875 12.113281 63.71875 11.042969 64.210938 10.085938 C 64.667969 9.210938 65.355469 8.480469 66.195312 7.976562 C 67.070312 7.464844 68.066406 7.203125 69.074219 7.222656 C 69.808594 7.207031 70.535156 7.386719 71.179688 7.746094 C 71.738281 8.0625 72.230469 8.480469 72.632812 8.980469 L 72.632812 7.464844 Z M 69.234375 17.128906 C 69.882812 17.144531 70.523438 16.964844 71.066406 16.613281 C 71.59375 16.265625 72.019531 15.777344 72.289062 15.207031 C 72.589844 14.582031 72.738281 13.894531 72.726562 13.195312 C 72.738281 12.511719 72.585938 11.835938 72.289062 11.222656 C 72.015625 10.652344 71.59375 10.167969 71.066406 9.824219 C 70.523438 9.472656 69.882812 9.292969 69.234375 9.308594 C 68.601562 9.296875 67.980469 9.472656 67.449219 9.816406 C 66.921875 10.15625 66.5 10.632812 66.226562 11.199219 C 65.929688 11.820312 65.78125 12.503906 65.792969 13.195312 C 65.78125 13.890625 65.929688 14.578125 66.226562 15.207031 C 66.503906 15.78125 66.929688 16.265625 67.460938 16.613281 C 67.988281 16.957031 68.605469 17.136719 69.234375 17.128906 Z M 69.234375 17.128906 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 81.492188 18.996094 L 79.246094 18.996094 L 79.246094 9.660156 L 76.867188 9.660156 L 76.867188 7.464844 L 79.246094 7.464844 L 79.246094 4.523438 L 81.492188 4.523438 L 81.492188 7.464844 L 84.160156 7.464844 L 84.160156 9.660156 L 81.492188 9.660156 Z M 81.492188 18.996094 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 95.710938 17.480469 L 95.6875 17.480469 C 95.128906 18 94.476562 18.414062 93.769531 18.699219 C 92.859375 19.082031 91.878906 19.257812 90.894531 19.21875 C 89.90625 19.175781 88.941406 18.917969 88.066406 18.460938 C 87.183594 17.976562 86.449219 17.253906 85.949219 16.371094 C 85.429688 15.453125 85.167969 14.410156 85.183594 13.355469 C 85.152344 12.199219 85.433594 11.054688 85.992188 10.046875 C 86.5 9.160156 87.242188 8.433594 88.132812 7.9375 C 89.019531 7.445312 90.015625 7.191406 91.03125 7.203125 C 91.949219 7.1875 92.859375 7.4375 93.648438 7.910156 C 94.4375 8.390625 95.085938 9.066406 95.527344 9.875 C 96.011719 10.773438 96.253906 11.78125 96.234375 12.800781 L 96.214844 13.765625 L 87.421875 13.765625 C 87.511719 14.726562 87.96875 15.613281 88.699219 16.238281 C 89.472656 16.867188 90.445312 17.191406 91.4375 17.148438 C 92.109375 17.179688 92.78125 17.023438 93.378906 16.699219 C 93.808594 16.445312 94.21875 16.152344 94.597656 15.832031 Z M 91.023438 9.289062 C 90.230469 9.269531 89.453125 9.476562 88.773438 9.890625 C 88.136719 10.292969 87.714844 10.988281 87.507812 11.96875 L 93.925781 11.96875 L 93.925781 11.824219 C 93.886719 11.347656 93.714844 10.890625 93.429688 10.507812 C 93.152344 10.132812 92.789062 9.824219 92.371094 9.617188 C 91.953125 9.402344 91.492188 9.289062 91.023438 9.289062 Z M 91.023438 9.289062 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 9.855469 3.59375 L 7.140625 4.722656 L 7.328125 5.175781 L 10.042969 4.042969 Z M 9.855469 3.59375 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 10 3.242188 L 6.796875 4.578125 L 7.183594 5.519531 L 10.390625 4.183594 Z M 10 3.242188 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 10.042969 3.148438 L 6.703125 4.539062 L 7.148438 5.617188 L 10.484375 4.226562 Z M 10.042969 3.148438 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 7 5.964844 L 6.359375 4.394531 L 10.179688 2.800781 L 10.828125 4.367188 Z M 7.046875 4.683594 L 7.28125 5.269531 L 10.128906 4.085938 L 9.894531 3.496094 Z M 7.046875 4.683594 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 10.042969 3.136719 L 6.691406 4.535156 L 7.140625 5.632812 L 10.492188 4.234375 Z M 10.042969 3.136719 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 7 5.976562 L 6.347656 4.390625 L 10.1875 2.789062 L 10.839844 4.375 Z M 7.035156 4.675781 L 7.285156 5.28125 L 10.152344 4.097656 L 9.898438 3.484375 Z M 7.035156 4.675781 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 6.957031 6.074219 L 6.25 4.351562 L 10.226562 2.691406 L 10.933594 4.414062 Z M 7.128906 4.714844 L 7.320312 5.1875 L 10.058594 4.046875 L 9.863281 3.574219 Z M 7.128906 4.714844 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 6.816406 6.417969 L 5.90625 4.207031 L 10.367188 2.34375 L 11.277344 4.558594 Z M 6.59375 4.496094 L 7.101562 5.726562 L 10.589844 4.269531 L 10.085938 3.039062 Z M 7.175781 5.535156 L 6.785156 4.574219 L 10.003906 3.230469 L 10.402344 4.191406 Z M 7.175781 5.535156 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 5.09375 6.742188 L 3.976562 9.46875 L 4.425781 9.652344 L 5.542969 6.929688 Z M 5.09375 6.742188 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 4.984375 6.398438 L 3.65625 9.625 L 4.59375 10.015625 L 5.921875 6.789062 Z M 4.984375 6.398438 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 4.914062 6.304688 L 3.539062 9.652344 L 4.605469 10.09375 L 5.976562 6.746094 Z M 4.914062 6.304688 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 4.773438 10.457031 L 3.21875 9.808594 L 4.800781 5.957031 L 6.359375 6.609375 Z M 3.90625 9.511719 L 4.488281 9.746094 L 5.667969 6.878906 L 5.082031 6.640625 Z M 3.90625 9.511719 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 4.9375 6.292969 L 3.546875 9.671875 L 4.636719 10.125 L 6.027344 6.746094 Z M 4.9375 6.292969 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 4.777344 10.472656 L 3.207031 9.8125 L 4.792969 5.945312 L 6.371094 6.609375 Z M 3.894531 9.523438 L 4.492188 9.773438 L 5.683594 6.890625 L 5.082031 6.636719 Z M 3.894531 9.523438 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 4.816406 10.566406 L 3.109375 9.855469 L 4.757812 5.851562 L 6.464844 6.5625 Z M 3.988281 9.488281 L 4.460938 9.679688 L 5.589844 6.929688 L 5.117188 6.730469 Z M 3.988281 9.488281 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 4.960938 10.914062 L 2.765625 9.996094 L 4.613281 5.492188 L 6.8125 6.410156 Z M 3.453125 9.710938 L 4.675781 10.21875 L 6.121094 6.707031 L 4.902344 6.195312 Z M 4.597656 10.027344 L 3.644531 9.628906 L 4.976562 6.386719 L 5.933594 6.785156 Z M 4.597656 10.027344 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 17.425781 6.765625 L 16.976562 6.949219 L 18.101562 9.6875 L 18.550781 9.5 Z M 17.425781 6.765625 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 17.570312 6.417969 L 16.632812 6.808594 L 17.957031 10.035156 L 18.894531 9.644531 Z M 17.570312 6.417969 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 17.609375 6.320312 L 16.539062 6.765625 L 17.921875 10.128906 L 18.992188 9.683594 Z M 17.609375 6.320312 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 17.777344 10.476562 L 16.191406 6.625 L 17.75 5.972656 L 19.332031 9.828125 Z M 16.882812 6.921875 L 18.058594 9.792969 L 18.636719 9.554688 L 17.464844 6.6875 Z M 16.882812 6.921875 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 17.613281 6.308594 L 16.523438 6.761719 L 17.914062 10.140625 L 19.003906 9.6875 Z M 17.613281 6.308594 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 17.773438 10.492188 L 16.175781 6.609375 L 17.75 5.953125 L 19.339844 9.824219 Z M 16.882812 6.90625 L 18.054688 9.792969 L 18.65625 9.542969 L 17.480469 6.65625 Z M 16.882812 6.90625 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 17.730469 10.585938 L 16.085938 6.582031 L 17.792969 5.871094 L 19.4375 9.875 Z M 16.964844 6.945312 L 18.097656 9.691406 L 18.566406 9.5 L 17.4375 6.742188 Z M 16.964844 6.945312 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 17.585938 10.933594 L 15.734375 6.429688 L 17.933594 5.511719 L 19.78125 10.015625 Z M 16.429688 6.722656 L 17.875 10.238281 L 19.097656 9.726562 L 17.652344 6.214844 Z M 17.953125 10.039062 L 16.617188 6.804688 L 17.585938 6.402344 L 18.917969 9.648438 Z M 17.953125 10.039062 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 12.710938 3.59375 L 12.523438 4.042969 L 15.238281 5.175781 L 15.421875 4.722656 Z M 12.710938 3.59375 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 12.566406 3.242188 L 12.179688 4.183594 L 15.382812 5.523438 L 15.769531 4.578125 Z M 12.566406 3.242188 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 12.527344 3.148438 L 12.085938 4.226562 L 15.421875 5.617188 L 15.863281 4.539062 Z M 12.527344 3.148438 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 15.5625 5.964844 L 11.742188 4.367188 L 12.382812 2.800781 L 16.207031 4.398438 Z M 12.429688 4.082031 L 15.277344 5.269531 L 15.511719 4.683594 L 12.667969 3.496094 Z M 12.429688 4.082031 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 12.519531 3.132812 L 12.070312 4.230469 L 15.421875 5.628906 L 15.875 4.535156 Z M 12.519531 3.132812 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 15.566406 5.976562 L 11.722656 4.375 L 12.378906 2.789062 L 16.222656 4.390625 Z M 12.410156 4.089844 L 15.28125 5.285156 L 15.53125 4.679688 L 12.664062 3.496094 Z M 12.410156 4.089844 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 15.609375 6.074219 L 11.632812 4.414062 L 12.335938 2.695312 L 16.3125 4.351562 Z M 12.507812 4.046875 L 15.234375 5.1875 L 15.429688 4.714844 L 12.703125 3.578125 Z M 12.507812 4.046875 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 15.75 6.421875 L 11.277344 4.558594 L 12.199219 2.34375 L 16.667969 4.210938 Z M 11.984375 4.269531 L 15.46875 5.726562 L 15.972656 4.496094 L 12.484375 3.039062 Z M 15.394531 5.539062 L 12.175781 4.191406 L 12.570312 3.230469 L 15.792969 4.574219 Z M 15.394531 5.539062 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 15.222656 16.667969 L 12.507812 17.796875 L 12.691406 18.25 L 15.410156 17.117188 Z M 15.222656 16.667969 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 15.367188 16.316406 L 12.164062 17.652344 L 12.550781 18.597656 L 15.757812 17.257812 Z M 15.367188 16.316406 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 15.40625 16.226562 L 12.070312 17.613281 L 12.511719 18.695312 L 15.847656 17.304688 Z M 15.40625 16.226562 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 12.371094 19.039062 L 11.722656 17.472656 L 15.546875 15.878906 L 16.191406 17.445312 Z M 12.410156 17.75 L 12.644531 18.335938 L 15.496094 17.148438 L 15.257812 16.566406 Z M 12.410156 17.75 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 15.410156 16.210938 L 12.058594 17.609375 L 12.507812 18.707031 L 15.859375 17.308594 Z M 15.410156 16.210938 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 12.363281 19.054688 L 11.703125 17.464844 L 15.554688 15.855469 L 16.207031 17.441406 Z M 12.410156 17.75 L 12.644531 18.359375 L 15.511719 17.171875 L 15.269531 16.566406 Z M 12.410156 17.75 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 15.59375 15.769531 L 16.300781 17.488281 L 12.324219 19.148438 L 11.617188 17.425781 Z M 15.421875 17.125 L 15.226562 16.648438 L 12.496094 17.792969 L 12.6875 18.265625 Z M 15.421875 17.125 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 12.175781 19.492188 L 11.269531 17.277344 L 15.738281 15.414062 L 16.644531 17.636719 Z M 11.957031 17.570312 L 12.460938 18.796875 L 15.957031 17.347656 L 15.449219 16.117188 Z M 12.539062 18.613281 L 12.148438 17.648438 L 15.371094 16.304688 L 15.769531 17.277344 Z M 12.539062 18.613281 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 18.101562 12.171875 L 16.976562 14.90625 L 17.421875 15.09375 L 18.546875 12.359375 Z M 18.101562 12.171875 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 17.960938 11.824219 L 16.632812 15.054688 L 17.570312 15.441406 L 18.894531 12.214844 Z M 17.960938 11.824219 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 17.917969 11.730469 L 16.535156 15.089844 L 17.605469 15.535156 L 18.988281 12.175781 Z M 17.917969 11.730469 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 17.75 15.882812 L 16.191406 15.234375 L 17.777344 11.382812 L 19.332031 12.035156 Z M 16.882812 14.9375 L 17.464844 15.175781 L 18.636719 12.304688 L 18.058594 12.066406 Z M 16.882812 14.9375 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 17.914062 11.71875 L 16.523438 15.09375 L 17.613281 15.550781 L 19 12.171875 Z M 17.914062 11.71875 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 17.753906 15.898438 L 16.175781 15.238281 L 17.765625 11.371094 L 19.34375 12.027344 Z M 16.882812 14.953125 L 17.480469 15.191406 L 18.65625 12.304688 L 18.054688 12.0625 Z M 16.882812 14.953125 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 17.730469 11.273438 L 19.441406 11.988281 L 17.792969 15.988281 L 16.085938 15.277344 Z M 18.5625 12.351562 L 18.089844 12.160156 L 16.960938 14.90625 L 17.433594 15.101562 Z M 18.5625 12.351562 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 17.9375 16.339844 L 15.738281 15.421875 L 17.589844 10.917969 L 19.785156 11.839844 Z M 16.429688 15.140625 L 17.652344 15.652344 L 19.097656 12.132812 L 17.875 11.625 Z M 17.585938 15.457031 L 16.628906 15.058594 L 17.964844 11.808594 L 18.917969 12.210938 Z M 17.585938 15.457031 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 4.449219 12.15625 L 4 12.339844 L 5.125 15.074219 L 5.574219 14.886719 Z M 4.449219 12.15625 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 4.589844 11.808594 L 3.65625 12.199219 L 4.984375 15.425781 L 5.917969 15.035156 Z M 4.589844 11.808594 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 4.632812 11.714844 L 3.5625 12.15625 L 4.941406 15.519531 L 6.011719 15.074219 Z M 4.632812 11.714844 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 4.800781 15.867188 L 3.21875 12.015625 L 4.773438 11.363281 L 6.359375 15.214844 Z M 3.90625 12.296875 L 5.082031 15.167969 L 5.667969 14.929688 L 4.488281 12.0625 Z M 3.90625 12.296875 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 4.640625 11.699219 L 3.550781 12.152344 L 4.9375 15.53125 L 6.027344 15.078125 Z M 4.640625 11.699219 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 4.792969 15.878906 L 3.207031 12.007812 L 4.777344 11.351562 L 6.371094 15.222656 Z M 3.894531 12.300781 L 5.070312 15.1875 L 5.671875 14.9375 L 4.492188 12.050781 Z M 3.894531 12.300781 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 4.816406 11.253906 L 6.464844 15.261719 L 4.757812 15.972656 L 3.109375 11.96875 Z M 5.589844 14.90625 L 4.453125 12.140625 L 3.984375 12.335938 L 5.117188 15.089844 Z M 5.589844 14.90625 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 4.613281 16.328125 L 2.765625 11.824219 L 4.960938 10.910156 L 6.808594 15.410156 Z M 3.453125 12.113281 L 4.898438 15.617188 L 6.121094 15.105469 L 4.675781 11.605469 Z M 4.976562 15.433594 L 3.644531 12.191406 L 4.597656 11.792969 L 5.929688 15.039062 Z M 4.976562 15.433594 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 7.3125 16.664062 L 7.125 17.117188 L 9.839844 18.25 L 10.027344 17.796875 Z M 7.3125 16.664062 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 7.167969 16.316406 L 6.785156 17.257812 L 9.984375 18.597656 L 10.375 17.652344 Z M 7.167969 16.316406 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 7.128906 16.21875 L 6.6875 17.296875 L 10.023438 18.6875 L 10.464844 17.613281 Z M 7.128906 16.21875 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 10.164062 19.039062 L 6.34375 17.445312 L 7 15.875 L 10.8125 17.472656 Z M 7.03125 17.15625 L 9.878906 18.339844 L 10.117188 17.753906 L 7.269531 16.570312 Z M 7.03125 17.15625 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 7.125 16.207031 L 6.675781 17.304688 L 10.027344 18.707031 L 10.480469 17.605469 Z M 7.125 16.207031 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 10.167969 19.054688 L 6.328125 17.449219 L 6.984375 15.855469 L 10.824219 17.464844 Z M 7.019531 17.15625 L 9.886719 18.34375 L 10.136719 17.75 L 7.265625 16.566406 Z M 7.019531 17.15625 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 6.941406 15.765625 L 10.917969 17.425781 L 10.210938 19.144531 L 6.238281 17.488281 Z M 10.039062 17.792969 L 7.304688 16.648438 L 7.113281 17.125 L 9.847656 18.265625 Z M 10.039062 17.792969 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(0%, 0%, 0%);
              fill-opacity: 1;
            "
            d="M 10.351562 19.496094 L 5.882812 17.632812 L 6.796875 15.421875 L 11.265625 17.28125 Z M 6.589844 17.34375 L 10.058594 18.796875 L 10.5625 17.570312 L 7.085938 16.117188 Z M 10 18.609375 L 6.765625 17.277344 L 7.164062 16.304688 L 10.386719 17.648438 Z M 10 18.609375 "
          />
        </g>
      </svg>
      <!-- Search -->
      <div class="searchDiv" v-if="$route.meta.showSearch">
        <input
          style="font-size: 12px"
          type="text"
          class="over-ellipsis-Auth"
          :placeholder="$t('search.placeholder')"
          v-model="searchVal"
          autocomplete="off"
          @keyup.enter="search()"
        /><button class="buttonres" @click="search()" style="border: white">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.2141 2.31429C3.61587 0.771429 5.65481 0 7.56631 0C9.47782 0 11.5168 0.771429 13.1734 2.31429C15.8495 5.01429 16.1044 9.25714 13.938 12.2143L17.761 16.0714C18.0159 16.3286 18.0159 16.7143 17.761 16.9714L16.869 17.8714C16.7415 18 16.6141 18 16.3592 18C16.1044 18 15.9769 18 15.8495 17.8714L12.0265 14.0143C10.6247 14.9143 9.09552 15.4286 7.56631 15.4286C5.65481 15.4286 3.61587 14.6571 2.2141 13.2429C-0.716874 10.1571 -0.716874 5.27143 2.2141 2.31429ZM3.99817 11.3143C4.89021 12.3429 6.16454 12.8571 7.56631 12.8571C8.96808 12.8571 10.2424 12.3429 11.1345 11.3143C12.1539 10.4143 12.6637 9.12857 12.6637 7.71429C12.6637 6.3 12.1539 5.01429 11.1345 4.11429C10.2424 3.08571 8.96808 2.57143 7.56631 2.57143C6.16454 2.57143 4.89021 3.08571 3.99817 4.11429C2.9787 5.01429 2.46897 6.3 2.46897 7.71429C2.46897 9.12857 2.9787 10.4143 3.99817 11.3143Z"
              fill="lightgrey"
            />
          </svg>
        </button>
      </div>
      <div
        class="blanc"
        v-if="!$route.meta.showSearch"
        style="margin-left: auto; margin-right: 0"
      ></div>
      <!-- Home -->
      <ul class="navbar-nav mr-4 menu homeTag">
        <li class="nav-item-1">
          <router-link class="nav-link" to="/homepage">
            {{ $t("authLayout.home") }}
          </router-link>
        </li>
      </ul>
      <!-- Blockchain -->
      <el-dropdown class="mr-4">
        <span
          class="el-dropdown-link droptitle drophide"
          :style="handleStyle(1)"
        >
          {{ $t("authLayout.blockchain") }}
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.93387 4.03915C8.73861 3.84388 8.42202 3.84388 8.22676 4.03915L6.04662 6.21929L3.92899 4.10165C3.73372 3.90638 3.41714 3.90638 3.22188 4.10165L2.86833 4.4552C2.67306 4.65046 2.67306 4.96705 2.86833 5.16231L5.69675 7.99074C5.89201 8.186 6.2086 8.186 6.40386 7.99074L6.75741 7.63718C6.77481 7.61979 6.79065 7.60143 6.80495 7.58228L9.28742 5.09981C9.48268 4.90454 9.48268 4.58796 9.28742 4.3927L8.93387 4.03915Z"
              fill="#4E5969"
            />
          </svg>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="toBlock()">
              {{ $t("authLayout.blocks") }}
            </el-dropdown-item>
            <el-dropdown-item @click="toTransaction()">
              {{ $t("authLayout.txs") }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <!-- Token -->
      <el-dropdown class="mr-4">
        <span
          class="el-dropdown-link droptitle drophide"
          :style="handleStyle(2)"
        >
          {{ $t("authLayout.tokenss") }}
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.93387 4.03915C8.73861 3.84388 8.42202 3.84388 8.22676 4.03915L6.04662 6.21929L3.92899 4.10165C3.73372 3.90638 3.41714 3.90638 3.22188 4.10165L2.86833 4.4552C2.67306 4.65046 2.67306 4.96705 2.86833 5.16231L5.69675 7.99074C5.89201 8.186 6.2086 8.186 6.40386 7.99074L6.75741 7.63718C6.77481 7.61979 6.79065 7.60143 6.80495 7.58228L9.28742 5.09981C9.48268 4.90454 9.48268 4.58796 9.28742 4.3927L8.93387 4.03915Z"
              fill="#4E5969"
            />
          </svg>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="toAsset()">
              {{ $t("authLayout.tokens") }}
            </el-dropdown-item>
            <el-dropdown-item @click="toContract()">
              {{ $t("authLayout.contracts") }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <!-- Address -->
      <el-dropdown class="mr-3">
        <span
          class="el-dropdown-link droptitle drophide"
          :style="handleStyle(3)"
        >
          {{ $t("authLayout.addresses") }}
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.93387 4.03915C8.73861 3.84388 8.42202 3.84388 8.22676 4.03915L6.04662 6.21929L3.92899 4.10165C3.73372 3.90638 3.41714 3.90638 3.22188 4.10165L2.86833 4.4552C2.67306 4.65046 2.67306 4.96705 2.86833 5.16231L5.69675 7.99074C5.89201 8.186 6.2086 8.186 6.40386 7.99074L6.75741 7.63718C6.77481 7.61979 6.79065 7.60143 6.80495 7.58228L9.28742 5.09981C9.48268 4.90454 9.48268 4.58796 9.28742 4.3927L8.93387 4.03915Z"
              fill="#4E5969"
            />
          </svg>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="toAddress()">
              {{ $t("authLayout.address") }}
            </el-dropdown-item>
            <el-dropdown-item @click="toCandidate()">
              {{ $t("authLayout.committee") }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <div class="mobileMenu" style="display: none">
        <el-dropdown>
          <i class="ni ni-align-center"></i>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="backHome()">{{
                $t("authLayout.home")
              }}</el-dropdown-item>
              <el-dropdown-item @click="toBlock()">{{
                $t("authLayout.blocks")
              }}</el-dropdown-item>
              <el-dropdown-item @click="toTransaction()">
                {{ $t("authLayout.txs") }}</el-dropdown-item
              >
              <el-dropdown-item @click="toAsset()">
                {{ $t("authLayout.tokens") }}</el-dropdown-item
              >
              <el-dropdown-item @click="toContract()">
                {{ $t("authLayout.contracts") }}</el-dropdown-item
              >
              <el-dropdown-item @click="toAddress()">
                {{ $t("authLayout.address") }}</el-dropdown-item
              >
              <el-dropdown-item @click="toCandidate()">
                {{ $t("authLayout.committee") }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <el-divider class="mr-3 drophide" direction="vertical"></el-divider>
      <!-- Languages -->
      <div class="mr-2 mb-1 drophide">
        <el-dropdown>
          <span class="el-dropdown-link droptitle">
            {{ this.lang }}
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.93387 4.03915C8.73861 3.84388 8.42202 3.84388 8.22676 4.03915L6.04662 6.21929L3.92899 4.10165C3.73372 3.90638 3.41714 3.90638 3.22188 4.10165L2.86833 4.4552C2.67306 4.65046 2.67306 4.96705 2.86833 5.16231L5.69675 7.99074C5.89201 8.186 6.2086 8.186 6.40386 7.99074L6.75741 7.63718C6.77481 7.61979 6.79065 7.60143 6.80495 7.58228L9.28742 5.09981C9.48268 4.90454 9.48268 4.58796 9.28742 4.3927L8.93387 4.03915Z"
                fill="#4E5969"
              />
            </svg>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="switch_the_language('en')"
                >English 🇬🇧</el-dropdown-item
              >
              <el-dropdown-item @click="switch_the_language('cn')"
                >中文 🇨🇳</el-dropdown-item
              >
              <el-dropdown-item @click="switch_the_language('fr')"
                >Français 🇫🇷</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <!-- Net -->
      <div
        class="mb-1 drophide dropTag"
        v-if="$route.meta.showNet"
        style="width: 100px"
      >
        <el-dropdown>
          <span class="el-dropdown-link droptitle">
            {{ this.netShow }}
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.93387 4.03915C8.73861 3.84388 8.42202 3.84388 8.22676 4.03915L6.04662 6.21929L3.92899 4.10165C3.73372 3.90638 3.41714 3.90638 3.22188 4.10165L2.86833 4.4552C2.67306 4.65046 2.67306 4.96705 2.86833 5.16231L5.69675 7.99074C5.89201 8.186 6.2086 8.186 6.40386 7.99074L6.75741 7.63718C6.77481 7.61979 6.79065 7.60143 6.80495 7.58228L9.28742 5.09981C9.48268 4.90454 9.48268 4.58796 9.28742 4.3927L8.93387 4.03915Z"
                fill="#4E5969"
              />
            </svg>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="switchNet('main')"
                >MainNet</el-dropdown-item
              >
              <el-dropdown-item @click="switchNet('testT5')"
                >TestT5</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </base-nav>

    <!-- Header -->

    <div v-if="$route.meta.HomePage" style="background: #f7f8fa"></div>
    <div v-else class="header py-6" style="background: #f7f8fa"></div>

    <router-view></router-view>
    <div
      v-if="$route.meta.showBot === true"
      style="
        border-top: 1px solid white;
        background-color: #fafafa;
        min-height: 300px;
      "
    >
      <div class="mainHome">
        <div class="row mt-5 mb-3">
          <div class="col-lg-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              style="cursor: pointer"
              class=""
              width="99pt"
              height="22pt"
              viewBox="0 0 99 22"
              version="1.1"
            >
              <g id="surface1">
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 28.433594 7.222656 C 29.46875 7.222656 30.269531 7.4375 30.835938 7.859375 C 31.402344 8.289062 31.820312 8.886719 32.023438 9.574219 C 32.257812 10.351562 32.371094 11.15625 32.363281 11.96875 L 32.363281 18.996094 L 30.117188 18.996094 L 30.117188 12.125 C 30.125 11.617188 30.050781 11.113281 29.898438 10.628906 C 29.765625 10.214844 29.507812 9.851562 29.15625 9.597656 C 28.71875 9.328125 28.203125 9.21875 27.695312 9.289062 C 27.15625 9.28125 26.621094 9.40625 26.148438 9.664062 C 25.714844 9.894531 25.347656 10.230469 25.078125 10.640625 C 24.816406 11.042969 24.679688 11.519531 24.683594 12 L 24.683594 18.996094 L 22.4375 18.996094 L 22.4375 7.464844 L 24.683594 7.464844 L 24.683594 9.289062 C 25.082031 8.699219 25.605469 8.207031 26.210938 7.847656 C 26.878906 7.433594 27.652344 7.214844 28.433594 7.222656 Z M 28.433594 7.222656 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 45.03125 17.480469 L 45.007812 17.480469 C 43.773438 18.578125 42.1875 19.195312 40.539062 19.214844 C 39.441406 19.238281 38.355469 18.980469 37.386719 18.457031 C 36.503906 17.972656 35.769531 17.25 35.269531 16.371094 C 34.75 15.449219 34.488281 14.410156 34.507812 13.351562 C 34.472656 12.195312 34.75 11.050781 35.3125 10.042969 C 35.824219 9.160156 36.5625 8.429688 37.453125 7.9375 C 38.339844 7.445312 39.335938 7.191406 40.351562 7.199219 C 41.269531 7.1875 42.179688 7.433594 42.96875 7.910156 C 43.757812 8.386719 44.40625 9.066406 44.851562 9.875 C 45.335938 10.769531 45.578125 11.777344 45.554688 12.796875 L 45.535156 13.765625 L 36.746094 13.765625 C 36.832031 14.722656 37.289062 15.613281 38.019531 16.234375 C 38.792969 16.859375 39.765625 17.183594 40.757812 17.148438 C 41.429688 17.175781 42.101562 17.023438 42.695312 16.699219 C 43.128906 16.441406 43.539062 16.152344 43.917969 15.828125 Z M 40.34375 9.289062 C 39.550781 9.269531 38.773438 9.476562 38.097656 9.890625 C 37.457031 10.292969 37.035156 10.988281 36.832031 11.96875 L 43.242188 11.96875 L 43.242188 11.824219 C 43.210938 11.34375 43.042969 10.882812 42.757812 10.496094 C 42.476562 10.117188 42.113281 9.8125 41.695312 9.601562 C 41.273438 9.390625 40.8125 9.285156 40.347656 9.289062 Z M 40.34375 9.289062 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 55.128906 3.488281 C 55.949219 3.480469 56.765625 3.605469 57.546875 3.859375 C 58.242188 4.089844 58.902344 4.414062 59.507812 4.824219 C 60.023438 5.171875 60.472656 5.601562 60.839844 6.101562 L 59.316406 7.707031 C 58.742188 7.15625 58.097656 6.695312 57.394531 6.332031 C 56.691406 5.972656 55.914062 5.789062 55.128906 5.792969 C 54.144531 5.78125 53.179688 6.03125 52.324219 6.519531 C 51.503906 6.988281 50.824219 7.667969 50.351562 8.484375 C 49.863281 9.34375 49.613281 10.320312 49.632812 11.308594 C 49.617188 12.308594 49.882812 13.292969 50.394531 14.152344 C 50.894531 14.988281 51.59375 15.6875 52.433594 16.171875 C 53.28125 16.667969 54.246094 16.921875 55.226562 16.910156 C 55.929688 16.914062 56.632812 16.769531 57.277344 16.480469 C 57.882812 16.21875 58.417969 15.8125 58.832031 15.296875 C 59.230469 14.8125 59.441406 14.207031 59.4375 13.582031 L 59.4375 13.460938 L 55.085938 13.460938 L 55.085938 11.113281 L 61.84375 11.113281 C 61.871094 11.335938 61.898438 11.554688 61.921875 11.773438 C 61.945312 11.996094 61.953125 12.214844 61.953125 12.433594 C 61.953125 13.238281 61.832031 14.035156 61.59375 14.804688 C 61.371094 15.550781 60.984375 16.242188 60.46875 16.824219 C 59.863281 17.578125 59.089844 18.175781 58.214844 18.570312 C 57.253906 19.007812 56.207031 19.230469 55.15625 19.21875 C 53.054688 19.238281 51.03125 18.414062 49.53125 16.933594 C 48.804688 16.21875 48.230469 15.363281 47.839844 14.417969 C 47.429688 13.433594 47.21875 12.375 47.226562 11.308594 C 47.21875 10.25 47.421875 9.203125 47.828125 8.226562 C 48.21875 7.296875 48.789062 6.453125 49.507812 5.746094 C 50.230469 5.03125 51.085938 4.46875 52.027344 4.089844 C 53.011719 3.683594 54.0625 3.480469 55.128906 3.488281 Z M 55.128906 3.488281 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 74.910156 7.464844 L 74.910156 18.996094 L 72.632812 18.996094 L 72.632812 17.21875 C 72.210938 17.769531 71.683594 18.230469 71.085938 18.578125 C 70.367188 19.015625 69.542969 19.234375 68.707031 19.214844 C 67.777344 19.222656 66.863281 18.957031 66.082031 18.445312 C 65.273438 17.917969 64.625 17.179688 64.199219 16.3125 C 63.71875 15.347656 63.472656 14.277344 63.492188 13.195312 C 63.46875 12.113281 63.71875 11.042969 64.210938 10.085938 C 64.667969 9.210938 65.355469 8.480469 66.195312 7.976562 C 67.070312 7.464844 68.066406 7.203125 69.074219 7.222656 C 69.808594 7.207031 70.535156 7.386719 71.179688 7.746094 C 71.738281 8.0625 72.230469 8.480469 72.632812 8.980469 L 72.632812 7.464844 Z M 69.234375 17.128906 C 69.882812 17.144531 70.523438 16.964844 71.066406 16.613281 C 71.59375 16.265625 72.019531 15.777344 72.289062 15.207031 C 72.589844 14.582031 72.738281 13.894531 72.726562 13.195312 C 72.738281 12.511719 72.585938 11.835938 72.289062 11.222656 C 72.015625 10.652344 71.59375 10.167969 71.066406 9.824219 C 70.523438 9.472656 69.882812 9.292969 69.234375 9.308594 C 68.601562 9.296875 67.980469 9.472656 67.449219 9.816406 C 66.921875 10.15625 66.5 10.632812 66.226562 11.199219 C 65.929688 11.820312 65.78125 12.503906 65.792969 13.195312 C 65.78125 13.890625 65.929688 14.578125 66.226562 15.207031 C 66.503906 15.78125 66.929688 16.265625 67.460938 16.613281 C 67.988281 16.957031 68.605469 17.136719 69.234375 17.128906 Z M 69.234375 17.128906 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 81.492188 18.996094 L 79.246094 18.996094 L 79.246094 9.660156 L 76.867188 9.660156 L 76.867188 7.464844 L 79.246094 7.464844 L 79.246094 4.523438 L 81.492188 4.523438 L 81.492188 7.464844 L 84.160156 7.464844 L 84.160156 9.660156 L 81.492188 9.660156 Z M 81.492188 18.996094 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 95.710938 17.480469 L 95.6875 17.480469 C 95.128906 18 94.476562 18.414062 93.769531 18.699219 C 92.859375 19.082031 91.878906 19.257812 90.894531 19.21875 C 89.90625 19.175781 88.941406 18.917969 88.066406 18.460938 C 87.183594 17.976562 86.449219 17.253906 85.949219 16.371094 C 85.429688 15.453125 85.167969 14.410156 85.183594 13.355469 C 85.152344 12.199219 85.433594 11.054688 85.992188 10.046875 C 86.5 9.160156 87.242188 8.433594 88.132812 7.9375 C 89.019531 7.445312 90.015625 7.191406 91.03125 7.203125 C 91.949219 7.1875 92.859375 7.4375 93.648438 7.910156 C 94.4375 8.390625 95.085938 9.066406 95.527344 9.875 C 96.011719 10.773438 96.253906 11.78125 96.234375 12.800781 L 96.214844 13.765625 L 87.421875 13.765625 C 87.511719 14.726562 87.96875 15.613281 88.699219 16.238281 C 89.472656 16.867188 90.445312 17.191406 91.4375 17.148438 C 92.109375 17.179688 92.78125 17.023438 93.378906 16.699219 C 93.808594 16.445312 94.21875 16.152344 94.597656 15.832031 Z M 91.023438 9.289062 C 90.230469 9.269531 89.453125 9.476562 88.773438 9.890625 C 88.136719 10.292969 87.714844 10.988281 87.507812 11.96875 L 93.925781 11.96875 L 93.925781 11.824219 C 93.886719 11.347656 93.714844 10.890625 93.429688 10.507812 C 93.152344 10.132812 92.789062 9.824219 92.371094 9.617188 C 91.953125 9.402344 91.492188 9.289062 91.023438 9.289062 Z M 91.023438 9.289062 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 9.855469 3.59375 L 7.140625 4.722656 L 7.328125 5.175781 L 10.042969 4.042969 Z M 9.855469 3.59375 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 10 3.242188 L 6.796875 4.578125 L 7.183594 5.519531 L 10.390625 4.183594 Z M 10 3.242188 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 10.042969 3.148438 L 6.703125 4.539062 L 7.148438 5.617188 L 10.484375 4.226562 Z M 10.042969 3.148438 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 7 5.964844 L 6.359375 4.394531 L 10.179688 2.800781 L 10.828125 4.367188 Z M 7.046875 4.683594 L 7.28125 5.269531 L 10.128906 4.085938 L 9.894531 3.496094 Z M 7.046875 4.683594 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 10.042969 3.136719 L 6.691406 4.535156 L 7.140625 5.632812 L 10.492188 4.234375 Z M 10.042969 3.136719 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 7 5.976562 L 6.347656 4.390625 L 10.1875 2.789062 L 10.839844 4.375 Z M 7.035156 4.675781 L 7.285156 5.28125 L 10.152344 4.097656 L 9.898438 3.484375 Z M 7.035156 4.675781 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 6.957031 6.074219 L 6.25 4.351562 L 10.226562 2.691406 L 10.933594 4.414062 Z M 7.128906 4.714844 L 7.320312 5.1875 L 10.058594 4.046875 L 9.863281 3.574219 Z M 7.128906 4.714844 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 6.816406 6.417969 L 5.90625 4.207031 L 10.367188 2.34375 L 11.277344 4.558594 Z M 6.59375 4.496094 L 7.101562 5.726562 L 10.589844 4.269531 L 10.085938 3.039062 Z M 7.175781 5.535156 L 6.785156 4.574219 L 10.003906 3.230469 L 10.402344 4.191406 Z M 7.175781 5.535156 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 5.09375 6.742188 L 3.976562 9.46875 L 4.425781 9.652344 L 5.542969 6.929688 Z M 5.09375 6.742188 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 4.984375 6.398438 L 3.65625 9.625 L 4.59375 10.015625 L 5.921875 6.789062 Z M 4.984375 6.398438 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 4.914062 6.304688 L 3.539062 9.652344 L 4.605469 10.09375 L 5.976562 6.746094 Z M 4.914062 6.304688 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 4.773438 10.457031 L 3.21875 9.808594 L 4.800781 5.957031 L 6.359375 6.609375 Z M 3.90625 9.511719 L 4.488281 9.746094 L 5.667969 6.878906 L 5.082031 6.640625 Z M 3.90625 9.511719 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 4.9375 6.292969 L 3.546875 9.671875 L 4.636719 10.125 L 6.027344 6.746094 Z M 4.9375 6.292969 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 4.777344 10.472656 L 3.207031 9.8125 L 4.792969 5.945312 L 6.371094 6.609375 Z M 3.894531 9.523438 L 4.492188 9.773438 L 5.683594 6.890625 L 5.082031 6.636719 Z M 3.894531 9.523438 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 4.816406 10.566406 L 3.109375 9.855469 L 4.757812 5.851562 L 6.464844 6.5625 Z M 3.988281 9.488281 L 4.460938 9.679688 L 5.589844 6.929688 L 5.117188 6.730469 Z M 3.988281 9.488281 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 4.960938 10.914062 L 2.765625 9.996094 L 4.613281 5.492188 L 6.8125 6.410156 Z M 3.453125 9.710938 L 4.675781 10.21875 L 6.121094 6.707031 L 4.902344 6.195312 Z M 4.597656 10.027344 L 3.644531 9.628906 L 4.976562 6.386719 L 5.933594 6.785156 Z M 4.597656 10.027344 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 17.425781 6.765625 L 16.976562 6.949219 L 18.101562 9.6875 L 18.550781 9.5 Z M 17.425781 6.765625 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 17.570312 6.417969 L 16.632812 6.808594 L 17.957031 10.035156 L 18.894531 9.644531 Z M 17.570312 6.417969 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 17.609375 6.320312 L 16.539062 6.765625 L 17.921875 10.128906 L 18.992188 9.683594 Z M 17.609375 6.320312 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 17.777344 10.476562 L 16.191406 6.625 L 17.75 5.972656 L 19.332031 9.828125 Z M 16.882812 6.921875 L 18.058594 9.792969 L 18.636719 9.554688 L 17.464844 6.6875 Z M 16.882812 6.921875 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 17.613281 6.308594 L 16.523438 6.761719 L 17.914062 10.140625 L 19.003906 9.6875 Z M 17.613281 6.308594 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 17.773438 10.492188 L 16.175781 6.609375 L 17.75 5.953125 L 19.339844 9.824219 Z M 16.882812 6.90625 L 18.054688 9.792969 L 18.65625 9.542969 L 17.480469 6.65625 Z M 16.882812 6.90625 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 17.730469 10.585938 L 16.085938 6.582031 L 17.792969 5.871094 L 19.4375 9.875 Z M 16.964844 6.945312 L 18.097656 9.691406 L 18.566406 9.5 L 17.4375 6.742188 Z M 16.964844 6.945312 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 17.585938 10.933594 L 15.734375 6.429688 L 17.933594 5.511719 L 19.78125 10.015625 Z M 16.429688 6.722656 L 17.875 10.238281 L 19.097656 9.726562 L 17.652344 6.214844 Z M 17.953125 10.039062 L 16.617188 6.804688 L 17.585938 6.402344 L 18.917969 9.648438 Z M 17.953125 10.039062 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 12.710938 3.59375 L 12.523438 4.042969 L 15.238281 5.175781 L 15.421875 4.722656 Z M 12.710938 3.59375 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 12.566406 3.242188 L 12.179688 4.183594 L 15.382812 5.523438 L 15.769531 4.578125 Z M 12.566406 3.242188 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 12.527344 3.148438 L 12.085938 4.226562 L 15.421875 5.617188 L 15.863281 4.539062 Z M 12.527344 3.148438 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 15.5625 5.964844 L 11.742188 4.367188 L 12.382812 2.800781 L 16.207031 4.398438 Z M 12.429688 4.082031 L 15.277344 5.269531 L 15.511719 4.683594 L 12.667969 3.496094 Z M 12.429688 4.082031 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 12.519531 3.132812 L 12.070312 4.230469 L 15.421875 5.628906 L 15.875 4.535156 Z M 12.519531 3.132812 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 15.566406 5.976562 L 11.722656 4.375 L 12.378906 2.789062 L 16.222656 4.390625 Z M 12.410156 4.089844 L 15.28125 5.285156 L 15.53125 4.679688 L 12.664062 3.496094 Z M 12.410156 4.089844 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 15.609375 6.074219 L 11.632812 4.414062 L 12.335938 2.695312 L 16.3125 4.351562 Z M 12.507812 4.046875 L 15.234375 5.1875 L 15.429688 4.714844 L 12.703125 3.578125 Z M 12.507812 4.046875 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 15.75 6.421875 L 11.277344 4.558594 L 12.199219 2.34375 L 16.667969 4.210938 Z M 11.984375 4.269531 L 15.46875 5.726562 L 15.972656 4.496094 L 12.484375 3.039062 Z M 15.394531 5.539062 L 12.175781 4.191406 L 12.570312 3.230469 L 15.792969 4.574219 Z M 15.394531 5.539062 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 15.222656 16.667969 L 12.507812 17.796875 L 12.691406 18.25 L 15.410156 17.117188 Z M 15.222656 16.667969 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 15.367188 16.316406 L 12.164062 17.652344 L 12.550781 18.597656 L 15.757812 17.257812 Z M 15.367188 16.316406 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 15.40625 16.226562 L 12.070312 17.613281 L 12.511719 18.695312 L 15.847656 17.304688 Z M 15.40625 16.226562 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 12.371094 19.039062 L 11.722656 17.472656 L 15.546875 15.878906 L 16.191406 17.445312 Z M 12.410156 17.75 L 12.644531 18.335938 L 15.496094 17.148438 L 15.257812 16.566406 Z M 12.410156 17.75 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 15.410156 16.210938 L 12.058594 17.609375 L 12.507812 18.707031 L 15.859375 17.308594 Z M 15.410156 16.210938 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 12.363281 19.054688 L 11.703125 17.464844 L 15.554688 15.855469 L 16.207031 17.441406 Z M 12.410156 17.75 L 12.644531 18.359375 L 15.511719 17.171875 L 15.269531 16.566406 Z M 12.410156 17.75 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 15.59375 15.769531 L 16.300781 17.488281 L 12.324219 19.148438 L 11.617188 17.425781 Z M 15.421875 17.125 L 15.226562 16.648438 L 12.496094 17.792969 L 12.6875 18.265625 Z M 15.421875 17.125 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 12.175781 19.492188 L 11.269531 17.277344 L 15.738281 15.414062 L 16.644531 17.636719 Z M 11.957031 17.570312 L 12.460938 18.796875 L 15.957031 17.347656 L 15.449219 16.117188 Z M 12.539062 18.613281 L 12.148438 17.648438 L 15.371094 16.304688 L 15.769531 17.277344 Z M 12.539062 18.613281 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 18.101562 12.171875 L 16.976562 14.90625 L 17.421875 15.09375 L 18.546875 12.359375 Z M 18.101562 12.171875 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 17.960938 11.824219 L 16.632812 15.054688 L 17.570312 15.441406 L 18.894531 12.214844 Z M 17.960938 11.824219 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 17.917969 11.730469 L 16.535156 15.089844 L 17.605469 15.535156 L 18.988281 12.175781 Z M 17.917969 11.730469 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 17.75 15.882812 L 16.191406 15.234375 L 17.777344 11.382812 L 19.332031 12.035156 Z M 16.882812 14.9375 L 17.464844 15.175781 L 18.636719 12.304688 L 18.058594 12.066406 Z M 16.882812 14.9375 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 17.914062 11.71875 L 16.523438 15.09375 L 17.613281 15.550781 L 19 12.171875 Z M 17.914062 11.71875 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 17.753906 15.898438 L 16.175781 15.238281 L 17.765625 11.371094 L 19.34375 12.027344 Z M 16.882812 14.953125 L 17.480469 15.191406 L 18.65625 12.304688 L 18.054688 12.0625 Z M 16.882812 14.953125 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 17.730469 11.273438 L 19.441406 11.988281 L 17.792969 15.988281 L 16.085938 15.277344 Z M 18.5625 12.351562 L 18.089844 12.160156 L 16.960938 14.90625 L 17.433594 15.101562 Z M 18.5625 12.351562 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 17.9375 16.339844 L 15.738281 15.421875 L 17.589844 10.917969 L 19.785156 11.839844 Z M 16.429688 15.140625 L 17.652344 15.652344 L 19.097656 12.132812 L 17.875 11.625 Z M 17.585938 15.457031 L 16.628906 15.058594 L 17.964844 11.808594 L 18.917969 12.210938 Z M 17.585938 15.457031 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 4.449219 12.15625 L 4 12.339844 L 5.125 15.074219 L 5.574219 14.886719 Z M 4.449219 12.15625 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 4.589844 11.808594 L 3.65625 12.199219 L 4.984375 15.425781 L 5.917969 15.035156 Z M 4.589844 11.808594 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 4.632812 11.714844 L 3.5625 12.15625 L 4.941406 15.519531 L 6.011719 15.074219 Z M 4.632812 11.714844 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 4.800781 15.867188 L 3.21875 12.015625 L 4.773438 11.363281 L 6.359375 15.214844 Z M 3.90625 12.296875 L 5.082031 15.167969 L 5.667969 14.929688 L 4.488281 12.0625 Z M 3.90625 12.296875 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 4.640625 11.699219 L 3.550781 12.152344 L 4.9375 15.53125 L 6.027344 15.078125 Z M 4.640625 11.699219 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 4.792969 15.878906 L 3.207031 12.007812 L 4.777344 11.351562 L 6.371094 15.222656 Z M 3.894531 12.300781 L 5.070312 15.1875 L 5.671875 14.9375 L 4.492188 12.050781 Z M 3.894531 12.300781 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 4.816406 11.253906 L 6.464844 15.261719 L 4.757812 15.972656 L 3.109375 11.96875 Z M 5.589844 14.90625 L 4.453125 12.140625 L 3.984375 12.335938 L 5.117188 15.089844 Z M 5.589844 14.90625 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 4.613281 16.328125 L 2.765625 11.824219 L 4.960938 10.910156 L 6.808594 15.410156 Z M 3.453125 12.113281 L 4.898438 15.617188 L 6.121094 15.105469 L 4.675781 11.605469 Z M 4.976562 15.433594 L 3.644531 12.191406 L 4.597656 11.792969 L 5.929688 15.039062 Z M 4.976562 15.433594 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 7.3125 16.664062 L 7.125 17.117188 L 9.839844 18.25 L 10.027344 17.796875 Z M 7.3125 16.664062 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 7.167969 16.316406 L 6.785156 17.257812 L 9.984375 18.597656 L 10.375 17.652344 Z M 7.167969 16.316406 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 7.128906 16.21875 L 6.6875 17.296875 L 10.023438 18.6875 L 10.464844 17.613281 Z M 7.128906 16.21875 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 10.164062 19.039062 L 6.34375 17.445312 L 7 15.875 L 10.8125 17.472656 Z M 7.03125 17.15625 L 9.878906 18.339844 L 10.117188 17.753906 L 7.269531 16.570312 Z M 7.03125 17.15625 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 7.125 16.207031 L 6.675781 17.304688 L 10.027344 18.707031 L 10.480469 17.605469 Z M 7.125 16.207031 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 10.167969 19.054688 L 6.328125 17.449219 L 6.984375 15.855469 L 10.824219 17.464844 Z M 7.019531 17.15625 L 9.886719 18.34375 L 10.136719 17.75 L 7.265625 16.566406 Z M 7.019531 17.15625 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 6.941406 15.765625 L 10.917969 17.425781 L 10.210938 19.144531 L 6.238281 17.488281 Z M 10.039062 17.792969 L 7.304688 16.648438 L 7.113281 17.125 L 9.847656 18.265625 Z M 10.039062 17.792969 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(0%, 0%, 0%);
                    fill-opacity: 1;
                  "
                  d="M 10.351562 19.496094 L 5.882812 17.632812 L 6.796875 15.421875 L 11.265625 17.28125 Z M 6.589844 17.34375 L 10.058594 18.796875 L 10.5625 17.570312 L 7.085938 16.117188 Z M 10 18.609375 L 6.765625 17.277344 L 7.164062 16.304688 L 10.386719 17.648438 Z M 10 18.609375 "
                />
              </g>
            </svg>
            <div class="mt-3">
              <small>{{ $t("authLayout.oneGate") }}</small>
            </div>
          </div>

          <div class="col-lg-7">
            <div class="row">
              <!-- Languages -->
              <div class="col-lg-3 mb-2 mt-2">
                <div class="dropbot" style="display: none">
                  <el-dropdown>
                    <el-button
                      size="mini"
                      plain
                      class="el-dropdown-link droptitle"
                    >
                      {{ this.lang }}
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8.93387 4.03915C8.73861 3.84388 8.42202 3.84388 8.22676 4.03915L6.04662 6.21929L3.92899 4.10165C3.73372 3.90638 3.41714 3.90638 3.22188 4.10165L2.86833 4.4552C2.67306 4.65046 2.67306 4.96705 2.86833 5.16231L5.69675 7.99074C5.89201 8.186 6.2086 8.186 6.40386 7.99074L6.75741 7.63718C6.77481 7.61979 6.79065 7.60143 6.80495 7.58228L9.28742 5.09981C9.48268 4.90454 9.48268 4.58796 9.28742 4.3927L8.93387 4.03915Z"
                          fill="#4E5969"
                        />
                      </svg>
                    </el-button>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item @click="switch_the_language('en')"
                          >English 🇬🇧</el-dropdown-item
                        >
                        <el-dropdown-item @click="switch_the_language('cn')"
                          >中文 🇨🇳</el-dropdown-item
                        >
                        <el-dropdown-item @click="switch_the_language('fr')"
                          >Français 🇫🇷</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
              </div>
              <!-- Net -->
              <div
                class="dropbot ml-3"
                style="display: none"
                v-if="$route.meta.showNet"
              >
                <el-dropdown>
                  <el-button
                    size="mini"
                    plain
                    class="el-dropdown-link droptitle"
                  >
                    {{ this.netShow }}
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.93387 4.03915C8.73861 3.84388 8.42202 3.84388 8.22676 4.03915L6.04662 6.21929L3.92899 4.10165C3.73372 3.90638 3.41714 3.90638 3.22188 4.10165L2.86833 4.4552C2.67306 4.65046 2.67306 4.96705 2.86833 5.16231L5.69675 7.99074C5.89201 8.186 6.2086 8.186 6.40386 7.99074L6.75741 7.63718C6.77481 7.61979 6.79065 7.60143 6.80495 7.58228L9.28742 5.09981C9.48268 4.90454 9.48268 4.58796 9.28742 4.3927L8.93387 4.03915Z"
                        fill="#4E5969"
                      />
                    </svg>
                  </el-button>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item @click="switchNet('main')"
                        >MainNet</el-dropdown-item
                      >
                      <el-dropdown-item @click="switchNet('testT5')"
                        >TestT5</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
              <div class="col-lg-3" style="line-height: 28px">
                <div class="mb-3">
                  <strong>{{ $t("authLayout.help") }}</strong>
                  <!--wallet-->
                </div>
                <div style="padding-bottom: 14px">
                  <a
                    class="text-muted"
                    title="Coming Soon~"
                    href="https://onegate.gitbook.io/onegate/manage-wallets"
                    target="_blank"
                    rel="noopener noreferrer"
                    >{{ $t("authLayout.wallet") }}</a
                  >
                  <!--nft-->
                </div>
                <div>
                  <a
                    class="text-muted"
                    title="Coming Soon~"
                    href="https://onegate.gitbook.io/onegate/nfts"
                    target="_blank"
                    rel="noopener noreferrer"
                    >{{ $t("authLayout.nft") }}</a
                  >
                </div>
              </div>
              <div class="col-lg-3" style="line-height: 28px">
                <div class="mb-3">
                  <strong>{{ $t("authLayout.developer") }}</strong>
                </div>
                <div>
                  <a
                    class="text-muted"
                    title="Coming Soon~"
                    href="javascript:"
                    >{{ $t("authLayout.dApi") }}</a
                  >
                </div>
              </div>
              <div class="col-lg-3" style="line-height: 28px">
                <div class="mb-3">
                  <strong>{{ $t("authLayout.contractUs") }}</strong>
                </div>
                <div style="padding-bottom: 14px">
                  <a
                    class="text-muted"
                    title="Twitter: OneGateWallet"
                    href="https://twitter.com/OneGateSpace"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      width="27"
                      height="22"
                      viewBox="0 0 27 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M26.1358 3.26512C25.2059 3.67759 24.2063 3.95634 23.1575 4.08168C24.228 3.43988 25.0504 2.42364 25.4375 1.21265C24.4354 1.80698 23.3257 2.23852 22.1444 2.47103C21.1985 1.46318 19.8509 0.833496 18.3593 0.833496C15.4955 0.833496 13.1734 3.15527 13.1734 6.01912C13.1734 6.42554 13.2193 6.82134 13.3078 7.20093C8.99789 6.98464 5.17673 4.92012 2.6191 1.78265C2.17269 2.54854 1.91696 3.43934 1.91696 4.38973C1.91696 6.18888 2.83243 7.77613 4.22393 8.70615C3.37394 8.67908 2.57423 8.44588 1.87512 8.05747C1.87463 8.07913 1.87463 8.10077 1.87463 8.12265C1.87463 10.6353 3.66218 12.7311 6.03445 13.2076C5.59933 13.3261 5.14119 13.3895 4.66825 13.3895C4.33409 13.3895 4.00923 13.3569 3.69254 13.2965C4.35247 15.3566 6.26758 16.856 8.53685 16.8977C6.76207 18.2887 4.52606 19.1178 2.09642 19.1178C1.67784 19.1178 1.26506 19.0931 0.859375 19.0451C3.15431 20.5165 5.88017 21.3752 8.80867 21.3752C18.3471 21.3752 23.5633 13.4733 23.5633 6.62042C23.5633 6.39562 23.5582 6.17197 23.5482 5.94952C24.5615 5.21837 25.4406 4.30506 26.1358 3.26512Z"
                        fill="#676c6c"
                      />
                    </svg>
                  </a>
                </div>
                <div>
                  <a class="text-muted" href="mailto:hello@onegate.space">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.75 3.75H2.25C1.83516 3.75 1.5 4.08516 1.5 4.5V19.5C1.5 19.9148 1.83516 20.25 2.25 20.25H21.75C22.1648 20.25 22.5 19.9148 22.5 19.5V4.5C22.5 4.08516 22.1648 3.75 21.75 3.75ZM19.8563 6.30234L12.4617 12.0563C12.2789 12.1992 12.0234 12.1992 11.8406 12.0563L4.44375 6.30234C4.41587 6.28082 4.39541 6.25112 4.38526 6.21739C4.37511 6.18367 4.37576 6.14761 4.38713 6.11427C4.3985 6.08094 4.42002 6.052 4.44867 6.0315C4.47731 6.01101 4.51165 5.99999 4.54688 6H19.7531C19.7883 5.99999 19.8227 6.01101 19.8513 6.0315C19.88 6.052 19.9015 6.08094 19.9129 6.11427C19.9242 6.14761 19.9249 6.18367 19.9147 6.21739C19.9046 6.25112 19.8841 6.28082 19.8563 6.30234Z"
                        fill="#676c6c"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p class="mt-5">
          <span
            >Copyright 2021 Onegate.<a
              class="text-muted"
              href="https://onegate.space/terms.html"
              target="_blank"
              >Terms</a
            >
            <span>&</span>
            <a
              class="text-muted"
              href="https://onegate.space/privacy.html"
              target="_blank"
              >Privacy</a
            >
          </span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Neon from "@cityofzion/neon-js";
import net from "../store/store";
export default {
  name: "auth-layout",
  data() {
    return {
      network: net.url,
      netShow: "Mainnet",
      searchVal: "",
      isHashPattern: /^((0x)?)([0-9a-f]{64})$/,
      isAssetPattern: /^((0x)?)([0-9a-f]{40})$/,
      isAddressPattern: /^N([0-9a-zA-Z]{33})$/,
      isNumberPattern: /^\d+$/,
      lang: "En",
      command: 0,
    };
  },

  created() {
    // if(localStorage.getItem("net")===null){
    //   localStorage.setItem("net","/bpi")
    // }
    let lang = this.$i18n.locale;
    if (lang === "cn") {
      this.lang = "Cn";
    } else if (lang === "en") {
      this.lang = "En";
    } else if (lang === "fr") {
      this.lang = "Fr";
    }
    if (`${location.hostname}` === "explorer.onegate.space") {
      if (this.$i18n.locale === "cn") {
        this.netShow = "主网";
      } else if (this.$i18n.locale === "en") {
        this.netShow = "Mainnet";
      } else if (this.$i18n.locale === "fr") {
        this.netShow = "Mainnet";
      }
    } else if (`${location.hostname}` === "testmagnet.explorer.onegate.space") {
      if (this.$i18n.locale === "cn") {
        this.netShow = "T5测试网";
      } else if (this.$i18n.locale === "en") {
        this.netShow = "TestT5";
      } else if (this.$i18n.locale === "fr") {
        this.netShow = "TestT5";
      }
    }
  },

  computed: {
    getUrl() {
      return this.$URL;
    },
  },
  methods: {
    // 语言切换
    switch_the_language(language) {
      this.$i18n.locale = language;
      if (language === "cn") {
        this.lang = "Cn";
        localStorage.setItem("lang", "cn");
        this.$refs.tabs.calcPaneInstances(true);
      } else if (language === "en") {
        this.lang = "En";
        localStorage.setItem("lang", "en");
        this.$refs.tabs.calcPaneInstances(true);
      } else if (language === "fr") {
        this.lang = "Fr";
        localStorage.setItem("lang", "fr");
        this.$refs.t.calcPaneInstances(true);
      }
    },
    switchNet(net) {
      if (net == "main") {
        // localStorage.setItem("net","/bpi")
        location.href =
          "https://explorer.onegate.space" + `${location.pathname}`;
      } else if (net == "testT5") {
        location.href =
          "https://testmagnet.explorer.onegate.space" + `${location.pathname}`;
      }
    },
    handleStyle(command) {
      switch (command) {
        case 1:
          if (
            this.$route.name === "blocks" ||
            this.$route.name === "transactions" ||
            this.$route.name === "blockinfo" ||
            this.$route.name === "transactionInfo"
          ) {
            return { color: "#165DFF" };
          }
          break;

        case 2:
          if (
            this.$route.name === "contractinfo" ||
            this.$route.name === "NftInfo" ||
            this.$route.name === "contracts" ||
            this.$route.name === "NFTtokeninfo" ||
            this.$route.name === "NEP17tokeninfo" ||
            this.$route.name === "VerifyContract" ||
            this.$route.name === "SourceCode" ||
            this.$route.name === "tokens"
          ) {
            return { color: "#165DFF" };
          }
          break;
        case 3:
          if (
            this.$route.name === "AccountProfile" ||
            this.$route.name === "Accounts" ||
            this.$route.name === "Candidates"
          ) {
            return { color: "#165DFF" };
          }
          break;
      }
    },
    backHome() {
      this.$router.push({
        path: `/homepage`,
      });
    },
    search() {
      let value = this.searchVal;
      value = value.trim();
      this.searchVal = "";
      if (value === "") {
        return;
      } else if (this.isHashPattern.test(value)) {
        if (value.length === 64) {
          value = "0x" + value;
        }
        this.getTransactionByTransactionHash(value);
      } else if (this.isAssetPattern.test(value)) {
        if (value.length === 40) {
          value = "0x" + value;
        }

        this.getToken(value);
      } else if (Number(value[0]) >= 0) {
        value = value.replace(/[,，]/g, "");
        if (!isNaN(Number(value)) && this.isNumberPattern.test(value)) {
          if (Number.isInteger(Number(value))) {
            this.getBlockByBlockHeight(value);
          }
        } else {
          this.$router.push({
            path: `/search`,
          });
        }
      } else if (this.isAddressPattern.test(value)) {
        this.getAddressByAddress(this.addressToScriptHash(value));
      } else {
        this.$router.push({
          path: `/search`,
        });
      }
    },
    toBlock() {
      this.$router.push({
        path: `/blocks/1`,
      });
    },
    toCandidate() {
      this.$router.push({
        path: `/candidates/1`,
      });
    },
    toTransaction() {
      this.$router.push({
        path: `/Transactions/1`,
      });
    },
    toAsset() {
      this.$router.push({
        path: `/tokens/Nep17/1`,
      });
    },
    toAddress() {
      this.$router.push({
        path: `/account/1`,
      });
    },
    toContract() {
      this.$router.push({
        path: `/contracts/1`,
      });
    },
    addressToScriptHash(addr) {
      try {
        const acc = Neon.create.account(addr);
        return "0x" + acc.scriptHash;
      } catch (err) {
        this.$router.push({
          path: `/search`,
        });
      }
    },
    getBlockByBlockHash(block_hash) {
      axios({
        method: "post",
        url: "/api",
        data: {
          jsonrpc: "2.0",
          id: 1,
          params: { BlockHash: block_hash },
          method: "GetBlockByBlockHash",
        },
        headers: {
          "Content-Type": "application/json",
          withCredentials: " true",
          crossDomain: "true",
        },
      }).then((res) => {
        if (res["data"]["error"] == null) {
          this.$router.push({
            path: `/blockinfo/${res["data"]["result"]["hash"]}`,
          });
        } else {
          this.$router.push({
            path: `/search`,
          });
        }
      });
    },
    getBlockByBlockHeight(blockheight) {
      axios({
        method: "post",
        url: "/api",
        data: {
          jsonrpc: "2.0",
          id: 1,
          params: { BlockHeight: parseInt(blockheight) },
          method: "GetBlockByBlockHeight",
        },
        headers: {
          "Content-Type": "application/json",
          withCredentials: " true",
          crossDomain: "true",
        },
      }).then((res) => {
        if (res["data"]["error"] == null) {
          this.$router.push({
            path: `/blockinfo/${res["data"]["result"]["hash"]}`,
          });
        } else {
          this.$router.push({
            path: `/search`,
          });
        }
      });
    },
    getAddressByAddress(addr) {
      axios({
        method: "post",
        url: "/api",
        data: {
          jsonrpc: "2.0",
          method: "GetAddressByAddress",
          params: { Address: addr },
          id: 1,
        },
        headers: {
          "Content-Type": "application/json",
          withCredentials: "true",
          crossDomain: "true",
        },
      }).then((res) => {
        // console.log("4")
        if (res["data"]["error"] == null) {
          this.$router.push({
            path: `/accountprofile/${addr}`,
          });
        } else {
          this.$router.push({
            path: `/search`,
          });
        }
      });
    },

    getToken(value) {
      axios({
        method: "post",
        url: "/api",
        data: {
          jsonrpc: "2.0",
          id: 1,
          params: { ContractHash: value },
          method: "GetAssetInfoByContractHash",
        },
        headers: {
          "Content-Type": "application/json",
          withCredentials: " true",
          crossDomain: "true",
        },
      }).then((res) => {
        if (res["data"]["error"] == null) {
          if (res["data"]["result"]["type"] === "NEP17") {
            this.$router.push({
              path: `/NEP17tokeninfo/${value}`,
            });
          } else if (res["data"]["result"]["type"] === "NEP11") {
            this.$router.push({
              path: `/NFTtokeninfo/${value}`,
            });
          } else {
            this.$router.push({
              path: `/contractinfo/${value}`,
            });
          }
          // console.log("2")
        } else {
          this.getContractInfoByContractHash(value);
        }
      });
    },
    getContractInfoByContractHash(value) {
      return new Promise(() => {
        axios({
          method: "post",
          url: "/api",
          data: {
            jsonrpc: "2.0",
            id: 1,
            params: { ContractHash: value },
            method: "GetContractByContractHash",
          },
          headers: {
            "Content-Type": "application/json",
            withCredentials: " true",
            crossDomain: "true",
          },
        }).then((res) => {
          if (res["data"]["error"] == null) {
            // console.log("3")
            this.$router.push({
              path: `/contractinfo/${value}`,
            });
          } else {
            this.getAddressByAddress(value);
          }
        });
      });
    },

    getTransactionByTransactionHash(value) {
      axios({
        method: "post",
        url: "/api",
        data: {
          jsonrpc: "2.0",
          id: 1,
          params: { TransactionHash: value },
          method: "GetRawTransactionByTransactionHash",
        },
        headers: {
          "Content-Type": "application/json",
          withCredentials: " true",
          crossDomain: "true",
        },
      }).then((res) => {
        if (res["data"]["error"] == null) {
          this.$router.push({
            path: `/transactionInfo/${value}`,
          });
        } else {
          this.getBlockByBlockHash(value);
        }
      });
    },
  },
};
</script>
<style>
.buttonres {
  cursor: pointer;
  position: absolute;
  right: 2px;
  top: 2px;
  bottom: 3px;
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: white !important;
  mix-blend-mode: normal;
  border-radius: 4px;
}

.over-ellipsis-Auth {
  width: 100%;
  height: 100%;
  padding-right: 15px;
  padding-left: 11px;
  font-size: 16px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgb(242, 242, 242);
  border-radius: 4px;
  outline-color: lightgrey;
}
.drop {
  width: 10%;
  margin-left: 1%;
}
.searchDiv {
  margin-left: auto;
  margin-right: 1%;
  width: 300px;
  background: white;
  height: 35px;
  position: relative;
}
.btn-icon svg:hover path {
  fill: rgb(85, 118, 236);
  fill-opacity: 1;
}
.btn-icon {
  padding: 0rem 0rem !important;
  border-radius: 100% !important;
  border: 0px solid transparent !important;
}
.footer-title {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 17px;
  text-align: center;
  color: #000000;
  margin-bottom: 20px;
}
.mainHome {
  width: 80%;
  height: 30%;
  /*top: 268px;*/
  margin: 0 auto;
  background: #fafafa;
  border-radius: 4px;
}
.custom-icon {
  font-size: 3px;
}
.logo {
  margin-left: 7.5%;
}

.homeTag {
}
.droptitle {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  /* identical to box height */

  mix-blend-mode: normal;
}
.droptitle:hover {
  color: #165dff;
}
.dropTag {
  width: 50px;
  margin-right: 7.5%;
}
.color {
  color: #165dff;
}

@media screen and (max-width: 1200px) {
  .navbar-nav {
    display: none !important;
  }
  .searchDiv {
    display: none;
  }
  .drop {
    display: none;
  }
  .mobileMenu {
    display: initial !important;
    margin-inline-start: auto;
  }
  .dropbot {
    display: initial !important;
  }
  .drophide {
    display: none;
  }
}
</style>
