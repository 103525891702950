const fr = {
  home: "Bienvenue sur OneGate Explorer",
  na: "non disponible",
  blockReward: " Bloc Récompense",
  networkFeeReward: "Récompense de frais de système",
  oracleFeeReward: "Récompense de frais Oracle",
  feeBurn: " Brûler la frais de transaction ",
  transferReward: "Récompense Transfert",
  mint: "Mouler",
  burn: "Brûler",
  transfer: "transférer",
  nullAddress: "Non disponible",
  nullBalance: "Non disponible",
  nullTransaction: "Non disponbile",
  hash: "Hash",
  wif: "WIF",
  page: "Page",
  bytes: "Octets",
  transactionId: "Transaction ID: ",
  gas: "Gas Consommé: ",
  size: "Taille",
  block: "Bloc Indice: ",
  ahash: "Txs Totals: ",
  overview: "Aperçu",
  blockDetail: "Details du Bloc",
  tokenDetail: "Details du Jeton",
  contractDetail: "Details du Contrat",
  nftDetail: "Details du NFT",
  overView: "Aperçu",
  allFilter: "Tout",
  transactionFilter: "Transaction",
  blockFilter: "Bloc",
  contractFilter: "Contrat",
  tokenFilter: "Jeton",
  addressFilter: "Addresse",
  authLayout: {
    blockchain: "Blockchain",
    addresses: "Adresses",
    tokenss: "Jetons",
    home: "Accueil",
    blocks: "Blocs",
    txs: "Transactions",
    tokens: "Jetons",
    contracts: "Contrats",
    address: "Addresses",
    committee: "Comité",
    help: "Aide",
    wallet: "Portefeuille",
    nft: "NFT",
    developer: "Développeur",
    dApi: "dAPI",
    contractUs: "Nous contacter",
    oneGate: "OneGate à la blockchain",
  },
  nftInfo: {
    name: "Nom",
    tokenId: "Jeton ID",
    symbol: "Symbol",
    contract: "Contrat Hash",
    address: "Porteur",
    description: "Description",
  },
  homePage: {
    totalBLocks: "Blocs Totals",
    totalTxs: "Transactions Totals",
    totalTokens: "Jetons Totals",
    totalCntrts: "Contrats Totals",
    totalAddrs: "Comptes Totals",
    totalCndidtes: "Candidatures Totals",
    recentBlocks: "Derniers Blocs",
    recentTxs: "Derniers Transactions",
    seeAll: "Voir Tout",
    more: "Plus",
    txTable: {
      txID: "Transaction ID",
      size: "Taille",
      time: "Temps",
      gas: "Gas Utilisé",
    },
  },
  blockinfo: {
    title: "Tableau Bloc",
    time: "Temps",
    bytes: "Octets",
    size: "Taille ",
    height: "Index",
    version: "Version",
    hash: "Bloc Hash",
    preHash: "Hash Précédent",
    txns: "Tx Somme",
    transfers: "Transferts",
    totalSysFee: "Frais de système totals",
    totalNetFee: "Frais de réseau totals",
    txnsList: "Tableau de Transaction",
    trfsList: "Tableau de transferts du bloc",
    nullPrompt: "Il n'y a pas de transaction dans ce bloc",
    speaker: "Speaker",
    blockReward: "Récompense de bloc",
    txTable: {
      txID: "Transaction ID",
      height: "Index de Bloc",
      size: "Taille",
      time: "Temps",
      gas: "Gas Utilisé ",
    },
  },
  contract: {
    title: "Tableau Contrat",
    search: "Rechercher par nom de contrat",
    hash: "Hash",
    name: "Nom",
    creator: "Créateur",
    index: "Indice",
    updates: "No.Mis À Jour",
    creatTime: "Temps créé",
    time: "Temps créé",
    available: "Non disponible",
    update: "Mise à jour le competeur",
    compiler: "Compilateur",
    txns: "Nombre de transaction",
    scCallTitle: "Derniers appels systèmes",
    txID: "Transaction ID",
    sender: "Envoyeur",
    method: "Méthode",
    callFlags: "callFlags",
    eventTitle: "Derniers événement",
    eventName: "Nom d'événement",
    vmState: "Statut de VM",
    conInfo: "Contract Info",
    sourceCode: "Code source",
    noScCall: "Ce contrat n'a pas d'appels systèmes ",
    noEvent: "Ce contrat n'a pas d'événement ",
    viewAsAddr: "Voir comme address",
  },
  transactionList: {
    title: "Tableau Transaction",
    transactionId: "Transaction ID ",
    blockHeight: "Indice Bloc",
    size: "Taille",
    time: "Temps",
    gasConsumed: "GAS Utilisé",
    page: "Page",
  },
  transactionInfo: {
    txId: "Transaction Info",
    time: "Temps",
    blockHeight: "Index de Bloc",
    size: "Taille",
    version: "Version",
    blockHash: "Bloc Hash",
    sender: "Expéditeur",
    netFee: "Frais de réseau",
    sysFee: "Frais de système",
    signers: "Signataires",
    account: "Compte",
    scopes: "Portées",
    witness: "Témoin",
    invocation: "Aufrufskript",
    verification: "Script d'appel",
    script: "Script",
    vmState: "VM état",
    exception: "Exception",
    trigger: "Déclencher",
    eventName: "Nom d'événement",
    contract: "Contrat",
    State: "Résultat",
    remind: " Ce transaction n'a pas de événement.",
    nep11: "Nep11 Transferts",
    nep17: "Nep17 Transferts",
    notification: "Évenement",
    systemCall: "Appel de Système",
    method: "Méthode",
    originSender: "Envoyeur original",
    callFlags: "Drapeau d'appel",
    params: "Paramètre",
    eventnull: "Ce transaction n'a pas de événement.",
    syscallnull: "Ce transaction n'a pas d'appel de système.",
  },
  transactionTransfer: {
    contract: "Contrat",
    token: "Jeton",
    nftName: "Nom",
    type: "Genre",
    from: "Envoyé de ",
    fromBalance: "Jetons d'Envoyeur",
    to: "Envoyé à",
    toBalance: "Jetons de Récepteur",
    amount: "Somme",
    tokenID: "ID Jeton",
    nep11null: "Il n'y a pas de Nep11 transfert.",
    nep17null: "Il n'y a pas de Nep17 transfert.",
  },
  transferList: {
    title: "Nep17 Transferts",
    contract: "Contrat",
    token: "Jeton",
    id: "ID",
    type: "Genre",
    from: "Envoyé de",
    fromBalance: " Jetons d'Envoyeur",
    to: "Envoyé à",
    toBalance: " Jetons de Récepteur",
    amount: "Somme",
    reward: "Récompense",
    burn: "Brûler",
    transfer: "Transférer",
    nullAccount: "Non Disponible",
    nullBalance: "Non Disponible",
  },
  tokensTable: {
    Nep11title: "NFTs",
    Nep17title: " Nep17",
    NeoTopHolders: "Top détenteurs NEO",
    GasTopHolders: "Top détenteurs GAS",
    BNeoTopHolders: "Top détenteurs bNEO",
    decimal: "Décimal",
    totalSupply: "Production Totale",
    hash: "Hash",
    tokenId: "TokenId",
    name: "Nom",
    nftName: "NFT Nom",
    symbol: "Symbole",
    standard: "Standard",
    holders: "Porteurs Totals",
    prompt: "Rechercher par nom de jeton",
  },
  tokenInfo: {
    name: "Nom de Jeton",
    symbol: " Symbole Jeton",
    nftToken: "NFT Jeton",
    transactions: "Transaction",
    decimal: "Décimal",
    standard: "Standard Support",
    transferred: "Premier Transfert",
    supply: "Production Totale",
    holders: "Porteurs Totals",
    recentTransfers: "Transfers",
    topHolders: "Top Porteurs",
    contractInfo: "Contrat Info",
    extra: "Extras",
    events: "Événements",
    methods: "Methodes",
    description: "Déscription",
    email: "E-mail",
    author: "Auteur",
    query: "Vérifier",
    params: "paramètres",
    returnType: "Type de résultat",
    offset: "décalage",
    safe: "sûreté",
    response: "Réponse",
    error: "Erreur",
    noParam: "Pas de Paramètre",
  },
  nftToken: {
    name: "Nom",
    tokenId: "Jeton ID",
    holder: "Porteur",
  },
  tokenTx: {
    instruction: "Instruction",
    txid: "Txid",
    type: "Genre",
    from: "Envoyé de",
    to: "Envoyé à",
    amount: "Somme",
    time: "Temps",
    tokenID: "Jeton id",
    nullPrompt: "Ce jeton n'a pas de transaction",
  },
  tokenHolder: {
    ranking: "Classement",
    address: "Addresse",
    balance: "Somme de jeton",
    percentage: "Pourcentage",
    nullPrompt: "Ce jeton n'a pas de porteur",
  },
  NftToken: {
    nullPrompt: "Ce jeton n'a pas de NFT",
  },
  addressPage: {
    number: "No.",
    address: "Addresse",
    neoBalance: "Nombre de NEO",
    gasBalance: "Nombre de GAS",
    Nep11Transfers: "Nombre de Nep11Transfers",
    Nep17Transfers: "Nombre de Nep17Transfers",
    createdTime: "Temps créé",
    hash: "Hash",
    accountsTable: "Tableau du Addresse",
    txnullPrompt: "Ce compte n'a pas de transaction",
    nep17nullPrompt: "Ce compte n'a pas de transerts NEP17",
    nep11nullPrompt: "Ce compte n'a pas de transerts NEP11.",
    tokennullPrompt: "Ce adresse n'a pas de jeton.",
    typeEnum: {
      normal: "Normal",
      committee: "Comité",
      candidate: "Candidature",
    },
    addressProfile: {
      title: "Compte ",
      type: "Genre",
      address: "Addresse",
      txNums: "Transactions",
      nep17Transfers: "NEP17 Transferts",
      nep11Transfers: "NEP11 Transferts",
      tokenBalance: "Nombre de jeton",
      tx: "Transactions",
      nep17title: "NEP17 Transferts",
      nep11title: "NEp11 Transferts",
    },
  },
  candidate: {
    title: "Candidats",
    committee: "Statut",
    address: "Addresse",
    rank: "Classement",
    votes: "Suffrage",
    percentage: "Pourcentage",
    consensus: "Consensus",
    committees: "Comité",
    candidate: "Candidat",
  },
  NftTable: {
    title: "NEP11 Transferts",
    contract: "Contrat",
    token: "Jeton",
    tokenId: "Jeton ID",
    type: "Genre",
    from: "Envoyé de",
    fromBalance: "Jetons d'Envoyeur",
    to: "Envoyé à",
    toBalance: "Jetons de Récepteur",
    amount: "Somme",
    mint: "Mouler",
    burn: "Brûler",
    transfer: "Transférer",
    nullAccount: "Non Disponible",
    nullBalance: "Non Disponible",
  },
  search: {
    placeholder:
      "Rechercher les Blocs, Transactions, Comptes, Jetons ou Contrats",
    information:
      "Pardon! Votre entrée ne correspond à aucun résultat dans le base de données.Vérifiez votre entrée, s'il vous plaît !",
    dataSupport: "Format de données qu'on supporte maintenant:",
    block: "Bloc Hash (UInt256), Bloc Index (Int)",
    transaction: "Transaction ID (UInt256)",
    account: "Compte Script Hash (UInt160), Compte Addresse (String)",
    token: "Jeton / Contrat Script Hash (UInt160)",
    button: "Retourner à la page d'accueil",
  },
  pageNotFound: {
    information_s: "Désolé!",
    information_t: "La page que vous recherchez n'exsite pas !",
    information_v:
      "Essayez de visiter dans quelque minutes ou vérifiez votre entrée.",
  },
};
export default fr;
