const en = {
  home: "Welcome To OneGate Explorer",
  na: "Not Available",
  blockReward: "Block Reward",
  networkFeeReward: "Network Fee Reward",
  oracleFeeReward: "Oracle Fee Reward",
  feeBurn: "Transaction Fee Burn",
  transferReward: "Transfer Reward",
  mint: "Mint",
  burn: "Burn",
  transfer: "Transfer",
  nullAddress: "Null Address",
  nullBalance: "Null Balance",
  nullTransaction: "Null Transaction",
  hash: "Hash",
  wif: "WIF",
  page: "Page",
  bytes: "Bytes",
  transactionId: "Transaction ID: ",
  gas: "Gas Consumed: ",
  size: "Size",
  block: "Block Index: ",
  ahash: "Txs Count: ",
  overview: "Overview",
  blockDetail: "Block Details",
  tokenDetail: "Token Details",
  nftDetail: "NFT Details",
  contractDetail: "Contract Details",
  overView: "Overview",
  allFilter: "All Filter",
  transactionFilter: "Transaction",
  blockFilter: "Block",
  contractFilter: "Contract",
  tokenFilter: "Token",
  addressFilter: "Address",
  authLayout: {
    blockchain: "Blockchain",
    addresses: "Addresses",
    tokenss: "Tokens",
    home: "Home",
    blocks: "Blocks",
    txs: "Transactions",
    tokens: "Tokens",
    contracts: "Contracts",
    address: "Addresses",
    committee: "Committee",
    help: "Help",
    wallet: "Wallet",
    nft: "NFT",
    developer: "Developer",
    dApi: "dAPI",
    contractUs: "Contact Us",
    oneGate: "OneGate to the Blockchain",
  },
  nftInfo: {
    name: "Name",
    tokenId: "Token ID",
    symbol: "Symbol",
    contract: "Contract Hash",
    address: "Holder",
    description: "Description",
  },
  homePage: {
    totalBLocks: "Total Blocks",
    totalTxs: "Total Transactions",
    totalTokens: "Total Tokens",
    totalCntrts: "Total Contracts",
    totalAddrs: "Total Addresses",
    totalCndidtes: "Total Candidates",
    recentBlocks: "Latest Blocks",
    recentTxs: "Latest Transactions",
    seeAll: "See All",
    more: "More",
    txTable: {
      txID: "Transaction ID",
      size: "Size",
      time: "Time",
      gas: "Gas Consumed",
    },
  },
  blockinfo: {
    title: "Block List",
    time: "Time",
    bytes: "Bytes",
    size: "Size",
    height: "Index",
    version: "Version Number",
    hash: "BlockHash",
    preHash: "PreHash",
    txns: "Txs Count",
    transfers: "Total Transfers",
    totalSysFee: "Total SystemFee",
    totalNetFee: "Total NetworkFee",
    txnsList: "Transaction List",
    trfsList: "Block Transfer List",
    nullPrompt: "This block has no transactions.",
    speaker: "Speaker",
    blockReward: "Block Reward",
    txTable: {
      txID: "Transaction ID",
      height: "Block Height",
      size: "Size",
      time: "Time",
      gas: "Gas",
    },
  },
  contract: {
    title: "Contract List",
    search: "Search by Contract Name",
    hash: "Hash",
    name: "Name",
    creator: "Creator",
    index: "Index",
    updates: "No.Updates",
    creatTime: "Create Time",
    time: "Create Time",
    available: "Not Available",
    update: "Updates",
    compiler: "Compiler",
    txns: "Tx Count",
    scCallTitle: "Recent ScCalls",
    txID: "transaction ID",
    sender: "Sender",
    method: "Method",
    callFlags: "callFlags",
    eventTitle: "Recent Events",
    eventName: "Event Name",
    vmState: "VM State",
    conInfo: "Contract Info",
    sourceCode: "Source Code",
    noScCall: "This Contract has no System Contract Calls.",
    noEvent: "This Contract has no events.",
    viewAsAddr: "View as Address",
  },
  transactionList: {
    title: "Transaction List",
    transactionId: "Transaction ID",
    blockHeight: "Block Height",
    size: "Size",
    time: "Time",
    gasConsumed: "GAS Consumed",
    page: "Page",
  },
  transactionInfo: {
    txId: "Transaction Details",
    time: "Time",
    blockHeight: "Block Height",
    size: "Size",
    version: "Version",
    blockHash: "Block Hash",
    sender: "Sender",
    netFee: "Network Fee",
    sysFee: "System Fee",
    signers: "Signers",
    account: "Address",
    scopes: "Scopes",
    witness: "Witnesses",
    invocation: "Invocation",
    verification: "Verification",
    script: "Script",
    vmState: "VM State",
    exception: "Exception",
    trigger: "Trigger",
    eventName: "Eventname",
    contract: "Contract",
    State: "State",
    remind: " This transaction has no event.",
    nep11: "Nep11 Transfers",
    nep17: "Nep17 Transfers",
    notification: "Transaction Event",
    systemCall: "System Call",
    method: "Method",
    originSender: "OriginSender",
    callFlags: "CallFlags",
    params: "Params",
    eventnull: "This transaction has no events.",
    syscallnull: "This transaction has no systemCall.",
  },
  transactionTransfer: {
    contract: "Contract",
    token: "Token",
    nftName: "Name",
    type: "Type",
    from: "From",
    fromBalance: "From balance",
    to: "To",
    toBalance: "To balance",
    amount: "Amount",
    tokenID: "TokenID",
    nep11null: "This transaction has no NEP11 Transfers.",
    nep17null: "This transaction has no NEP17 Transfers.",
  },
  transferList: {
    title: "Nep17 Transfers",
    contract: "Contract",
    token: "Token",
    id: "TokenId",
    type: "Type",
    from: "From",
    fromBalance: "From Balance",
    to: "To",
    toBalance: "To Balance",
    amount: "Amount",
    reward: "Reward",
    burn: "Burn",
    transfer: "Transfer",
    nullAccount: "Null Address",
    nullBalance: "Not Available",
  },
  tokensTable: {
    Nep11title: "Explore NFTs",
    Nep17title: "Nep17 ",
    NeoTopHolders: "NEO TOP Holders",
    GasTopHolders: "GAS TOP Holders",
    BNeoTopHolders: "bNEO TOP Holders",
    decimal: "Decimal",
    totalSupply: "Total Supply",
    hash: "Hash",
    tokenId: "TokenId",
    nftName: "NftName",
    name: "Name",
    symbol: "Symbol",
    standard: "Standard",
    holders: "Total Holders",
    prompt: "Search by Token Name",
  },
  tokenInfo: {
    name: "Token Name",
    nftToken: "NFT Token",
    transactions: "Transactions",
    symbol: "Token Symbol",
    decimal: "Decimal",
    standard: "Supported Standard",
    transferred: "First Transferred",
    supply: "Total Supply",
    holders: "Total Holders",
    recentTransfers: "Transfers",
    topHolders: "Top Holders",
    contractInfo: "Contract Info",
    extra: "Extras",
    events: "Events",
    methods: "Methods",
    description: "Description",
    email: "Email",
    author: "Author",
    query: "Query",
    params: "parameters",
    returnType: "return type",
    offset: "offset",
    safe: "safe",
    response: "Response",
    error: "Error",
    noParam: "No Parameters",
  },
  tokenTx: {
    instruction: "Instruction",
    txid: "Txid",
    type: "Transaction Type",
    from: "From",
    to: "To",
    amount: "Amount",
    time: "Time",
    tokenID: "Tokenid",
    nullPrompt: "This token has no transactions.",
  },
  nftToken: {
    name: "Name",
    tokenId: "Token ID",
    holder: "Holder",
  },
  tokenHolder: {
    ranking: "Ranking",
    address: "Address",
    balance: "Balance",
    percentage: "Percentage",
    nullPrompt: "This token has no holders.",
  },
  NftToken: {
    nullPrompt: "This Token has no NFT supply",
  },
  addressPage: {
    number: "No.",
    address: "Address List",
    neoBalance: "Neo Balance",
    gasBalance: "Gas Balance",
    Nep11Transfers: "Nep11 Transfers",
    Nep17Transfers: "Nep17 Transfers",
    createdTime: "Created Time",
    hash: "Hash",
    accountsTable: "Address List",
    tokennullPrompt: "This address has no tokens.",
    txnullPrompt: "This address has no transactions.",
    nep17nullPrompt: "This address has no NEP17 transfers.",
    nep11nullPrompt: "This address has no NEP11 transfers.",
    typeEnum: {
      normal: "Normal",
      committee: "Committee",
      candidate: "Candidate",
    },
    addressProfile: {
      title: "Address Details",
      address: "Address",
      type: "Type",
      txNums: "Transactions",
      nep17Transfers: "NEP17 Transfers",
      nep11Transfers: "NEP11 Transfers",
      tokenBalance: "Token Balance",
      tx: "Transactions",
      nep17title: "NEP17 Transfers",
      nep11title: "NEP11 Transfers",
    },
  },
  candidate: {
    title: "Candidate List",
    committee: "Status",
    address: "Address",
    rank: "Ranking",
    votes: "Votes",
    percentage: "Percentage",
    consensus: "Consensus",
    committees: "Committee",
    candidate: "Candidate",
  },
  NftTable: {
    title: "NEP11 Transfers",
    contract: "Contract",
    token: "Token",
    tokenId: "Token ID",
    type: "Type",
    from: "From",
    fromBalance: "From Balance",
    to: "To",
    toBalance: "To Balance",
    amount: "Amount",
    mint: "Mint",
    burn: "Burn",
    transfer: "Transfer",
    nullAccount: "Null Address",
    nullBalance: "Not Available",
  },
  search: {
    placeholder:
      "Search for Blocks, Transactions, Addresses, Tokens or Contracts",
    information:
      "Sorry! Your entry did not match any results in our database. Please check your entry",
    dataSupport: " Data formats we currently support:",
    block: "Block Hash (UInt256), Block Height (Int)",
    transaction: "Transaction ID (UInt256)",
    account: "Address Script Hash (UInt160), Address (String)",
    token: "Token / Contract Script Hash (UInt160)",
    button: "Back to Home Page",
  },
  pageNotFound: {
    information_s: "Sorry!",
    information_t: "The Page You Visited Is Not Available.",
    information_v: "Try visiting in a few minutes and check your path input.",
  },
  bottomBar: {
    description:
      "Neo Explorer is a Block Explorer and Analytics Platform for Neo, a decentralized smart contracts platform.",
    developers: "Developers",
    tooling: "Tooling",
    examples_and_tutorials: "Examples and tutorials",
    documentation: "Documentation",
    eco_support: "Eco-Support",
    for_token_holders: "For token holders",
    governance: "Governance",
    neo_and_gas: "NEO & GAS",
    wallet: "Wallet",
    blogs: "Blogs",
    events: "Events",
    contact_ngd: "Contact NGD",
    join_us: "Join US",
    marketing: "Marketing",
    ecogrowth: "EcoGrowth",
    general: "General",
    for_media: "For Media",
    about_neo: "About Neo",
    press_kit: "Press Kit",
    keep_in_touch: "Keep in touch",
  },
};
export default en;
