const zh = {
  home: "欢迎使用OneGate Explorer",
  na: "不适用",
  blockReward: "区块奖励",
  networkFeeReward: "网络费奖励",
  oracleFeeReward: "预言机奖励",
  feeBurn: "手续费燃烧",
  transferReward: "转账奖励",
  mint: "铸币交易",
  burn: "销币交易",
  transfer: "转账",
  nullAddress: "空地址",
  nullBalance: "不适用",
  nullTransaction: "暂无交易",
  hash: "哈希",
  wif: "地址",
  page: "页",
  bytes: "字节",
  transactionId: "交易ID: ",
  gas: "消耗Gas: ",
  size: "大小: ",
  block: "区块高度: ",
  ahash: "交易数量: ",
  overview: "总览",
  blockDetail: "区块详情",
  contractDetail: "合约详情",
  nftDetail: "NFT 详情",
  allFilter: "所有",
  transactionFilter: "交易",
  blockFilter: "区块",
  contractFilter: "合约",
  tokenFilter: "通证",
  addressFilter: "地址",
  authLayout: {
    blockchain: "区块链",
    addresses: "地址",
    tokenss: "合约代币",
    home: "主页",
    blocks: "区块",
    txs: "交易",
    tokens: "通证",
    contracts: "合约",
    address: "地址",
    committee: "候选人",
    help: "帮助",
    wallet: "钱包",
    nft: "NFT",
    developer: "开发者",
    dApi: "dAPI",
    contractUs: "联系我们",
    oneGate: "发现区块链新世界",
  },
  nftInfo: {
    name: "名称",
    tokenId: "代币ID",
    symbol: "代币符号",
    contract: "合约",
    address: "持有人",
    description: "描述",
  },
  homePage: {
    totalBLocks: "区块总数",
    totalTxs: "交易总数",
    totalTokens: "通证总数",
    totalCntrts: "合约总数",
    totalAddrs: "地址总数",
    totalCndidtes: "候选人",
    recentBlocks: "区块",
    recentTxs: "交易",
    seeAll: "更多",
    more: "更多",
    txTable: {
      txID: "交易哈希",
      size: "大小",
      time: "时间",
      gas: "消耗Gas",
    },
  },
  blockinfo: {
    title: "区块列表",
    time: "创建时间",
    bytes: "字节",
    size: "区块大小",
    height: "区块高度",
    version: "版本号",
    hash: "区块哈希",
    preHash: "父区块哈希",
    txns: "交易数",
    transfers: "总转账数",
    totalSysFee: "系统燃料费",
    totalNetFee: "网络燃料费",
    txnsList: "交易列表",
    trfsList: "区块转账列表",
    nullPrompt: "该区块未包含交易。",
    speaker: "议长节点",
    blockReward: "出块奖励",
    txTable: {
      txID: "交易哈希",
      height: "区块高度",
      size: "区块大小",
      time: "出块时间",
      gas: "Gas",
    },
  },
  contract: {
    title: "合约列表",
    search: "通过合约名称搜索",
    hash: "合约哈希",
    name: "合约名",
    creator: "合约创建者",
    index: "合约序号",
    updates: "更新次数",
    creatTime: "创建时间",
    time: "创建时间",
    available: "不适用",
    update: "更新次数",
    compiler: "编译器",
    txns: "交易数",
    scCallTitle: "近期调用",
    txID: "交易哈希",
    sender: "创建地址",
    method: "方法",
    callFlags: "调用标志",
    eventTitle: "近期事件",
    eventName: "事件名称",
    vmState: "虚拟机状态",
    conInfo: "合约信息",
    sourceCode: "合约源码",
    noScCall: "该合约暂无调用",
    noEvent: "该合约暂无事件",
    viewAsAddr: "以地址检索",
  },
  transactionList: {
    title: "交易列表",
    transactionId: "交易哈希",
    blockHeight: "区块高度",
    size: "大小",
    time: "时间",
    gasConsumed: "消耗的Gas",
    page: "页数",
  },
  transactionInfo: {
    txId: "交易信息",
    time: "时间",
    blockHeight: "区块高度",
    size: "大小",
    version: "版本",
    blockHash: "区块哈希",
    sender: "发件人",
    netFee: "网络费",
    sysFee: "系统费",
    signers: "签名人",
    account: "账户",
    scopes: "范围",
    witness: "证人",
    invocation: "调用脚本",
    verification: "验证脚本",
    script: "运行脚本",
    vmState: "虚拟机执行结果",
    exception: "异常",
    trigger: "执行",
    eventName: "事件名称",
    contract: "合约",
    State: "结果",
    remind: " 这个交易中不包含事件",
    nep11: "Nep11转账",
    nep17: "Nep17转账",
    notification: "执行事件",
    systemCall: "合约调用",
    method: "调用方法",
    originSender: "初始调用者",
    callFlags: "调用标志",
    params: "传入参数",
    eventnull: "这笔交易不包含事件.",
    syscallnull: "这笔交易不包含合约调用.",
  },
  transactionTransfer: {
    contract: "合约",
    token: "通证",
    nftName: "名称",
    type: "交易类型",
    from: "转出账户",
    fromBalance: "转出账户余额",
    to: "转入账户",
    toBalance: "转入账户余额",
    amount: "金额",
    tokenID: "通证ID",
    nep11null: "这笔交易不包含Nep11转账.",
    nep17null: "这笔交易不包含Nep17转账.",
  },
  transferList: {
    title: "Nep17 交易",
    contract: "合约",
    token: "通证",
    id: "通证ID",
    type: "交易类型",
    from: "转出账户",
    fromBalance: "转出账户通证数量",
    to: "转入账户",
    toBalance: "转入账户通证数量",
    amount: "转账数额",
    reward: "转账奖励",
    burn: "销币交易",
    transfer: "转账",
    nullAccount: "不适用",
    nullBalance: "不适用",
  },
  tokensTable: {
    Nep11title: "NFT 代币列表",
    Nep17title: "Nep17 代币列表",
    NeoTopHolders: "NEO 资产排名",
    GasTopHolders: "GAS 资产排名",
    BNeoTopHolders: "bNEO 资产排名",
    decimal: "小数位数",
    totalSupply: "总供给",
    hash: "哈希",
    tokenId: "通证Id",
    nftName: "NFT名称",
    name: "名称",
    symbol: "符号",
    standard: "合约标准",
    holders: "持有人总数",
    prompt: "以通证名称搜索",
  },
  tokenInfo: {
    name: "通证名称",
    symbol: "通证符号",
    nftToken: "NFT 代币",
    transactions: "交易",
    decimal: "小数位数",
    standard: "合约标准",
    transferred: "首次交易时间",
    supply: "总供给",
    holders: "持有人总数",
    recentTransfers: "转账记录",
    topHolders: "持有人地址",
    contractInfo: "合约信息",
    extra: "额外信息",
    events: "定义事件",
    methods: "合约方法",
    description: "描述信息",
    email: "邮箱",
    author: "合约作者",
    query: "查询",
    params: "参数列表",
    returnType: "返回类型",
    offset: "偏移量",
    safe: "是否安全",
    response: "返回结果",
    error: "错误",
    noParam: "无参数",
  },
  nftToken: {
    name: "名称",
    tokenId: "通证 ID",
    holder: "持有人",
  },
  tokenTx: {
    instruction: "指令",
    txid: "交易哈希",
    type: "交易类型",
    from: "转出地址",
    to: "转入地址",
    amount: "转账数额",
    time: "时间",
    tokenID: "通证ID",
    nullPrompt: "该通证暂无交易记录。",
  },
  tokenHolder: {
    ranking: "资产排名",
    address: "地址",
    balance: "余额",
    percentage: "资产占比",
    nullPrompt: "该通证暂无持有人。",
  },
  NftToken: {
    nullPrompt: "代币不包含NFT",
  },
  addressPage: {
    number: "序号",
    address: "地址列表",
    neoBalance: "Neo余额",
    gasBalance: "Gas余额",
    Nep11Transfers: "Nep11标准交易数",
    Nep17Transfers: "Nep17标准交易数",
    createdTime: "创建时间",
    accountsTable: "地址列表",
    hash: "哈希",
    tokennullPrompt: "该账户不包含通证。",
    txnullPrompt: "该账户不包含交易。",
    nep17nullPrompt: "该账户不包含NEP17转账。",
    nep11nullPrompt: "该账户不包含NEP11转账。",
    typeEnum: {
      normal: "普通账户",
      committee: "委员会",
      candidate: "候选人",
    },
    addressProfile: {
      title: "地址",
      type: "类型",
      address: "地址",
      txNums: "交易数",
      nep17Transfers: "NEP17转账数",
      nep11Transfers: "NEP11转账数",
      tokenBalance: "通证余额",
      tx: "交易记录",
      nep17title: "NEP17转账记录",
      nep11title: "NEP11转账记录",
    },
  },
  candidate: {
    title: "候选人列表",
    committee: "身份",
    address: "地址",
    rank: "排名    ",
    votes: "投票",
    percentage: "占比",
    consensus: "共识节点",
    committees: "委员会成员",
    candidate: "候选人",
  },
  NftTable: {
    title: "NEP11 交易",
    contract: "合约",
    token: "通证",
    tokenId: "通证ID",
    type: "交易类型",
    from: "转出账户",
    fromBalance: "转出账户通证数量",
    to: "转入账户",
    toBalance: "转入账户通证数量",
    amount: "转账数额",
    mint: "铸币交易",
    burn: "销币交易",
    transfer: "转账",
    nullAccount: "空地址",
    nullBalance: "不适用",
  },
  search: {
    placeholder: "搜索区块，交易，账户，通证或者合约",
    information: "抱歉，您要查询的数据不在区块链上，请您核对查询信息.",
    dataSupport: "我们支持的数据格式如下:",
    block: "区块哈希 (UInt256), 区块高度 (Int)",
    transaction: "交易ID (UInt256)",
    account: "账户脚本哈希 (UInt160), 账户地址 (String)",
    token: "通证/合约脚本哈希 (UInt160)",
    button: "返回首页",
  },
  pageNotFound: {
    information_s: "抱歉!",
    information_t: "您访问的界面不存在",
    information_v: "请过几分钟后再试，或者检查输入路径",
  },
  bottomBar: {
    description:
      "Neo Explorer是去中心化的智能合约平台Neo的区块浏览器和分析平台。",
    developers: "开发者",
    tooling: "工具",
    examples_and_tutorials: "案例与教学",
    documentation: "开发者文档",
    eco_support: "生态支持",
    for_token_holders: "社区成员",
    governance: "治理",
    neo_and_gas: "NEO & GAS",
    wallet: "钱包",
    blogs: "博客",
    events: "活动",
    contact_ngd: "联系 ",
    join_us: "加入我们",
    marketing: "市场运营",
    ecogrowth: "生态发展",
    general: "一般事务",
    for_media: "媒体",
    about_neo: "关于 Neo",
    press_kit: "媒体资源",
    keep_in_touch: "保持联系",
  },
};
export default zh;
